import React from "react";
import {
  arrowMarkUp,
  DataAiDash,
  Expense,
  Mobiles,
  RawdataSvg,
} from "../../../utility/assetsManager";
import { LIGHT_FONT } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomBrilliance from "../../components/custom_brilliance";
import CustomButton from "../../components/custom_button";
import MainHomeComponent from "../../components/Main_component";
import "./index.css";

const LeftComponentMain = ({ width }) => {
  return (
    <div
      style={{
        margin: "20px 0px",
      }}
      className="flex flex-col items-right lg:items-start justify-center lg:px-16"
    >
      <p
        style={{ ...styles.ready }}
        className="text-4xl w-full text-start  md:text-5xl md:w-full md:text-start"
      >
        Get insights of your user's financial health
      </p>

      <p style={{ ...styles.para }} className="w-full  text-2xl lg:text-2xl  ">
        Generate your error free monthly expense report swiftly on a single
        click.
      </p>
      <a href="/contact-us">
        {width > 645 && <CustomButton
          title="Book an appointment"
          icon={
            <img
              src={arrowMarkUp}
              style={{
                height: width > 645 ? "12px" : "9px",
                width: width > 645 ? "12px" : "9px",
                marginBottom: "2px",
                marginLeft: "-2px",
              }}
              alt="arrowMarkUphome"
            />
          }
          style={{
            marginTop: 20,
            padding: width > 645 ? "10px 20px" : "5px 15px",
          }}
        />}
      </a>
    </div>
  );
};

const RightComponent = ({
  img,
  style,
  width,
  alt = "",
  isImageRequired = true,
}) => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignSelf: "center",
        padding: 20,
      }}
    >
      {isImageRequired && (
        <img
          src={img}
          style={{
            objectFit: "contain",
            minWidth: 300,
            width: width > 645 ? width * 5 : width * 0.5,
            maxWidth: 600,
            ...style,
          }}
          alt={alt}
        />
      )}
    </div>
  );
};
const RightComponent1 = ({ img, style, width }) => {
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",

        // position: "relative",
        // backgroundColor: "red",
      }}
    >
      <img
        src={img}
        style={{
          minWidth: 320,
          objectFit: "contain",
          width: width * 0.4,
          maxWidth: 750,

          // position: "absolute",
          // top: -18,
          ...style,
        }}
        alt="rightcomp1"
      />
    </div>
  );
};
const LeftComponent = ({ title1, title2, para, li, color1, color2, width }) => {
  return (
    <div style={{ paddingBottom: "5%" }}>
      <div
        style={{
          fontWeight: "600",
          background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
          width: "fit-content",
          paddingBottom: "20px",
          marginTop: 20
        }}
      >
        <p
          className="text-3xl sm:text-4xl	md:text-4xl"
          style={{
            lineHeight: "1.3",
            fontWeight: "700",
            fontFamily: "Satoshi",
          }}
        >
          {title1}
        </p>
        <p
          style={{
            lineHeight: "1.3",
            fontWeight: "700",
            fontFamily: "Satoshi",
          }}
          className="text-3xl sm:text-4xl	md:text-4xl"
        >
          {title2}
        </p>
      </div>
      <p
        style={{
          fontFamily: "'Satoshi'",
          fontStyle: "normal",
          maxWidth: "400px",
          fontWeight: 500,
          wordBreak: "break-word",
          paddingTop: 10,
          paddingBottom: 10,
          color: color1
        }}
      // className=" lg:text-left "
      >
        {para}
      </p>
      <ul
        style={{
          ...styles.liStyles,
          paddingLeft: "2%",
          lineHeight: "40px",
          color: color2,
        }}
      >
        {li}
      </ul>
    </div>
  );
};

function DataAi() {
  const { height, width } = ResizeListener();
  return (
    <div>
      <div
        style={{
          height: "fit-content",
          position: "relative",
          padding: 0,
          margin: 0,
        }}
      >
        <MainHomeComponent
          height={height}
          width={width}
          leftcomponent={<LeftComponentMain width={width} />}
          rightcomponent={
            <img
              src={Mobiles}
              style={{
                position: "absolute",
                // left: width < 780 ? "20%" : "30%",
                bottom: -50,
                minWidth: width > 700 ? 300 : 250,
                objectFit: "contain",
                width: width * 0.3,
                maxWidth: 500,
              }}
              alt="mobiles"
            />
          }
          rightStyle={{
            minWidth: 250,
            maxWidth: 350,
            minHeight: 250,
            display: width >= 1030 ? "none" : "",
            position: width < 1030 && "relative",
            height: "100%",
          }}
          style={{
            backgroundColor: "#1E2234",
            // height: width > 900 ? "60vh" : "80vh",
            borderRadius: 0,
            // backgroundColor: "green",
            display: "flex",
            justifyContent: "flex-start",
            padding: "0px 15px",
            // margin: 0,
            // padding: 0,
          }}
          leftStyle={{
            padding: 0,
            margin: 0,
            marginTop: width > 900 ? 0 : 60,

            // backgroundColor: "red",
          }}
        />
        <img
          src={Mobiles}
          width="59%"
          style={{
            position: "absolute",
            bottom: -300,
            right: 70,
            minWidth: 550,
            minHeight: 300,
            maxWidth: 550,
            maxHeight: 550,
            display: width < 1030 ? "none" : "",
          }}
          alt="mobile1"
        />
      </div>

      <div>
        <MainHomeComponent
          height={height}
          width={width}
          style={{
            // backgroundColor: "#FFFFFF",

            height: "100%",
            borderRadius: 0,
            paddingTop: 0,
            paddingBottom: 40,
            marginLeft: "0%",
          }}
          leftcomponent={
            <LeftComponent
              width={width}
              style={{}}
              color1={"#6C6B6B"}
              color2={"#1E2234"}
              li={
                <>
                  <li className="text-sm lg:text-base ">
                    Fewer delays and errors
                  </li>
                  <li className="text-sm lg:text-base ">
                    Saves time, cost, and effort
                  </li>
                  <li className="text-sm lg:text-base">
                    Custom data/report/account
                  </li>
                  <li className="text-sm lg:text-base">
                    It’s easy to create and stick to your budget
                  </li>
                </>
              }
              title1={"Get 360° view of your user's financial insights"}

              para={
                "Manage and make the best business decisions with a complete solution to reduce overspending and increase productivity."
              }
            />
          }
          // leftStyle={{
          //   margin: width< 700?80:20

          // }}
          leftStyle={{
            margin: "auto",
            padding: "5% 1% 0%",
            marginTop: width < 630 ? "60px" : "1%",
          }}
          rightcomponent={
            <RightComponent width={width} alt="comp1" isImageRequired={false} />
          }
        />
      </div>
      <div>
        <MainHomeComponent
          height={height}
          width={width}
          style={{
            backgroundColor: "#1E2234",
            height: width < 645 ? "90vh" : "83vh",
            borderRadius: 0,
            // padding: "5% 2% 0 0%",
            padding: '30px 10px',
            // paddingBottom:100
          }}
          leftcomponent={
            <LeftComponent
              color1={"#CBCACA"}
              color2={"#FDFDFD"}
              li={
                <>
                  <li className="text-sm lg:text-base">
                    Supports user-defined categories
                  </li>
                  <li className="text-sm lg:text-base">
                    Self-learning sophisticated algorithm
                  </li>
                  <li className="text-sm lg:text-base">
                    Compatible with any transaction data source
                  </li>
                  <li className="text-sm lg:text-base">
                    Detect every income source and transactions{" "}
                  </li>
                </>
              }
              title1={"Turn your raw data into "}
              title2={"actionable insights."}
              para={
                "Our transaction categorization solution is built to recognize every unassigned transactions and turn it into defined categories based on their description, amount, date,and contextual meta data."
              }
            />
          }
          leftStyle={{ margin: "auto" }}
          rightcomponent={
            <RightComponent width={width} img={RawdataSvg} alt="comp2" />
          }
          rightStyle={{
            padding: width < 900 ? "" : "2% 10% 5% 2% ",
            marginLeft: "10px",
          }}

        />
      </div>
      <div>
        <MainHomeComponent
          height={height}
          width={width}
          style={{
            backgroundColor: "#FFF6EF",
            height: "100%",
            borderRadius: 0,
            padding: "1% 0 0 6%",
          }}
          leftcomponent={
            <LeftComponent
              color1={"#6C6B6B"}
              color2={"#1E2234"}
              li={
                <>
                  <li className="text-sm lg:text-base ">
                    Instant and precise detection of merchant
                  </li>
                  <li className="text-sm lg:text-base ">
                    Detailed information of the merchant: Location, Name,
                    Contact Details
                  </li>
                  <li className="text-sm lg:text-base">
                    Mechanism based on an extensive list of more than 20 million
                    merchants in India
                  </li>
                </>
              }
              title1={"Get complete detail of your, every expense."}
              para={
                "API identifies the exact merchant of a transaction and offers detailed information about it. This will in result help in marketing campaigns, provide end-users with personalized analytics reports, and much more."
              }
            />
          }
          leftStyle={{ margin: "auto" }}
          rightcomponent={<RightComponent1 width={width} img={Expense} />}
          rightStyle={{
            padding: 0,
            margin: 0,
            height: "100%",
            marginTop: 20,
          }}
        />
      </div>
      <div>
        <MainHomeComponent
          height={height}
          width={width}
          style={{
            backgroundColor: "#1E2234",
            height: "100%",
            paddingBottom: 0,
            borderRadius: 0,
            padding: "5% 0 0 6%"
          }}
          leftcomponent={
            <LeftComponent
              color1={"#CBCACA"}
              color2={"#CBCACA"}
              li={
                <>
                  <li className="text-sm lg:text-base">
                    Identify the suitable user to promote your products
                  </li>
                  <li className="text-sm lg:text-base">
                    Determine the right users to target
                  </li>
                  <li className="text-sm lg:text-base">
                    Curated offers for users, based on their spending habits
                  </li>
                </>
              }
              title1={"Dashboard to analyse"}
              title2={"your customer data."}
              para={
                "The insights provide detailed information on customer spending and savings behavior. It is crafted using complex machine learning algorithms that process transactional information from customer’s bank account."
              }
            />
          }
          leftStyle={{ margin: "auto", padding: "2% 0%" }}
          rightcomponent={
            <RightComponent
              width={width}
              img={DataAiDash}
              style={{
                marginBottom: 20,
              }}
              alt="comp3"
            />
          }
          rightStyle={{
            padding: 0,
            margin: 0,
            height: "100%",
            // backgroundColor: "red",
          }}
        />
      </div>

      <CustomBrilliance />
    </div>
  );
}
const styles = {
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  liStyles: {
    listStyleType: "disc",
    minWidth: 300,
    maxWidth: 450,
  },
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    lineHeight: 1.3,
    marginBottom: "15px",
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    color: LIGHT_FONT,
    fontWeight: "14px",
    maxWidth: "400px",
    minWidth: "200px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

export default DataAi;

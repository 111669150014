import React from "react";
import CustomText from "../custom_text";
import { WHITE } from "../../../utility/colorManager";

function CustomButton({
  title,
  onClick,
  style,
  textStyle,
  icon,
  backgroundColor,
  width,
  disabled = false,
}) {
  return (
    <button
      style={{
        // padding: width > 645 ? "12px 17px" : "10px 10px",
        borderRadius: 7,
        background: backgroundColor
          ? ""
          : "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
        ...style,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <CustomText
        style={{
          fontSize: width > 645 ? 16 : 14,
          color: WHITE,
          ...textStyle,
        }}
      >
        {title}
      </CustomText>

      <div
        style={{
          marginLeft: 10,
          marginTop: 3,
        }}
      >
        {icon}
      </div>
    </button>
  );
}

export default CustomButton;

export const PRIMARY_COLOR = "#38369C";
export const WHITE = "#ffffff";

export const BLACK = "#000000";

export const BUTTON_COLOR = "#FF9800";

export const SECONDARY_COLOR = "#FF9800";
export const LIGHT_TEXT_COLOR = "#6C6B6B";

export const DARK_BLUE = "#1E2234";

export const LIGHT_FONT = "#CBCACA";

export const WHITE_BG = "#F9F9FB";

// import { ReactComponent as appstorewhite } from "../assets/svg/appApple.svg";
import girl from "../assets/png/girl.png";
import { ReactComponent as aadhar } from "../assets/svg/aadhar.svg";
import { ReactComponent as appstore } from "../assets/svg/applestore.svg";
import { ReactComponent as documentcode } from "../assets/svg/documentcode.svg";
import googleplay from "../assets/svg/googleplay.svg";
import { ReactComponent as ekyc1 } from "../assets/svg/KYC_1.svg";
import { ReactComponent as ekyc2 } from "../assets/svg/KYC_2.svg";
import { ReactComponent as scalable } from "../assets/svg/scalable.svg";
import { ReactComponent as secure } from "../assets/svg/secure.svg";
import { ReactComponent as story } from "../assets/svg/story.svg";
import { ReactComponent as WhiteApplestore } from "../assets/svg/whiteapplestore.svg";
import { ReactComponent as PoliciLogo } from "../assets/svg/Polici Logo.svg";

import certificationBadge from "../assets/png/certificationBadge.png";
import phone1 from "../assets/png/phone1.png";
import phone2 from "../assets/png/phone2.png";
import phone3 from "../assets/png/phone3.png";
import phone4 from "../assets/png/phone4.png";
import wallets from "../assets/png/Wallet.png";
import cards from "../assets/png/Card.png";
import recive from "../assets/png/Fund-Recieve.png";
import send from "../assets/png/Fund-Send.png";
import rewards from "../assets/png/Reward.png";

import product1 from "../assets/png/Product-1.png";
import product2 from "../assets/png/Product-2.png";
import product3 from "../assets/png/Product-3.png";
import product4 from "../assets/png/Product-4.png";
import product5 from "../assets/png/Product-5.png";
import product6 from "../assets/png/Product-6.png";
import product7 from "../assets/png/Product-7.png";
import product8 from "../assets/png/Product-8.png";

import grouppeople from "../assets/png/grouppeople.jpg";
import gifton from "../assets/png/gifton.png";
import Cshape from "../assets/png/cshape.png";
import blue_image_web from "../assets/png/blue_image_web.png";
import blue_image_mobile from "../assets/png/blue_image_Mobile.png";
import icon_rupee from "../assets/png/load-fund.png";
import wallet_card_icon from "../assets/png/wallet_icon.png";
import tansx_card_icon from "../assets/png/trnsx.png";
import settllement_icon from "../assets/png/settlement.png";
import safe_card_icon from "../assets/png/safe.png";
import programSetUp_card_icon from "../assets/png/Program-Setup.png";
import Money from "../assets/png/money.png";
import NetworkIcon from "../assets/png/Network-Icon.png";
import LayoffIcon from "../assets/png/Lay-off_Lay-Off (1) 1.png";
import FinanceIcon from "../assets/png/Web-Icon Polici_Finance 1.png";
import PayBillsIcon from "../assets/png/Finance-Support_Financial-Support 1.png";

import PoliciIcon from "../assets/png/Polici-Icons.png";
import virtual_card_icon from "../assets/png/phy-virtualcard.png";
import collectBot_card_icon from "../assets/png/link-collecbot.png";
import cardCycle_card_icon from "../assets/png/Card-man.png";
import control_card_icon from "../assets/png/card-control.png";
import cardLife_card_icon from "../assets/png/Card-life.png";
import corporate_catrd_icon from "../assets/png/corporate.png";
import fleets_card_icon from "../assets/png/fleets.png";
import neobank_card_icon from "../assets/png/neobank.png";
import platForm_card_icon from "../assets/png/platform.png";
import scalability_card_icon from "../assets/png/scalable.png";
import flee_card_icon from "../assets/png/flee.png";
import certification_card_icon from "../assets/png/certification.png";

import dataaidash from "../assets/png/dataaidash.png";
import expense from "../assets/png/expense.png";
import mobiles from "../assets/png/mobiles.png";
import certified from "../assets/png/certified.png";
import mobilekey from "../assets/png/mobilekey.png";
import keybg from "../assets/png/keybg.png";
import neokey from "../assets/png/neokey.png";
import neostrap from "../assets/png/neostrap.png";
import neoloop from "../assets/png/neoloop.png";
import collectbotvan from "../assets/png/collectbotvan.png";
import dashboard from "../assets/png/dashboard.png";
import yesbank from "../assets/png/yesbank.png";
import giftonmobdash from "../assets/png/giftonmobdash.png";
import giftondash from "../assets/png/giftondash.png";
import tarun from "../assets/png/tarun.png";
import vivek from "../assets/png/vivek.png";
import dipali from "../assets/png/dipali.png";
import harish from "../assets/png/harish.png";
import rohith from "../assets/png/rohith.png";
import homelogo from "../assets/png/homelogo.png";
import success from "../assets/png/success.png";
import glass from "../assets/png/glass.png";

export const VerificationSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/verification.svg";
export const Roatate3dSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/3drotate.svg";
export const CreditScoreSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/creditscore.svg";
export const ReceiptSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/receipt.svg";
export const ReceiptSearchSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/receiptsearch.svg";
export const UserSearchSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/usersearch.svg";
export const ShieldSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/shield.svg";

export const VideoSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/video.svg";
export const ScanSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/scan.svg";
export const IdSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/id.svg";
export const FacialSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/facial.svg";
export const EkycSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/ekyc.svg";
export const OcrSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/ocr.svg";

export const AppStoreWhite =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/appApple.svg";

export const blackA =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/blackapple.svg";
export const Apple =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/Apple.svg";

export const NeokredLogo =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/neokred_logo.svg";
export const OH =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/Oh_no.svg";
export const Look_like =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/look.svg";
export const CardImg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/cards.svg";
export const HomeLogo1 =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/home_image1.svg";

export const arrowMarkUp =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/Vector.svg";
export const Certified =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/frame_98.svg";
export const BankLogo =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/banklogos.svg";
export const EllipseSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/Ellipse.svg";
export const whiteapple1 =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/whiteapple.svg";

export const GiftonVideo =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/giftonvideo.svg";
export const Error1 =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/error.svg";

export const GiftonPortal =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/giftonportal.svg";
export const GiftonMobile =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/giftonmobile.svg";
export const KycVideo =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/kycvideo.svg";
export const KycSvg1 =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/KYC.svg";

export const CodeSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/code.svg";

export const ProfilexVideoSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/profilexvideo.svg";

export const NameCardSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/namecard.svg";
export const JobProfileSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/jobprofile.svg";
export const CreditScoreSvg2 =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/creditscore2.svg";
export const AddressSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/address.svg";
export const DocumentsSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/documents.svg";

export const HandSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/hand.svg";
export const NklogoSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/nklogo.svg";
export const TarunSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/tarun.svg";
export const DipaliSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/dipali.svg";
export const VivekSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/vivek.svg";
export const RohitSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/rohit.svg";
export const HarishSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/harish.svg";

export const LinkedinSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/linkedin.svg";
export const BekindSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/bekind.svg";
export const ThinkteamSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/thinkteam.svg";
export const WorksmartSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/worksmart.svg";

export const StatesmanSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/statesman.svg";
export const RevopreneurSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/revopreneur.svg";
export const EconomicSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/economic.svg";
export const DailyfinanceSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/dailyfinance.svg";
export const StartupSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/stratup.svg";
export const MintSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/mint.svg";
export const Inc42Svg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/inc42.svg";
export const DeccanSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/deccan.svg";
export const CollectbotvideoSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/collectbotvideo.svg";
export const CollectbotvanSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/collectbotvan.svg";

export const DashboardSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/dashboard.svg";

export const MobilekeySvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/mobilekey.svg";
export const WearablevideoSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/wearablevideo.svg";
export const KeybgSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/keybg.svg";

export const NeokeySvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/neokey.svg";
export const NeoloopSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/neoloop.svg";
export const NeostrapSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/neostrap.svg";

export const CodesSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/codes.svg";
export const ManwithlaptopSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/manwithlaptop.svg";

export const WhatwehaveSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/whatwehave.svg";

export const FleetSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/fleet.svg";
export const UniversitySvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/university.svg";
export const BuildingsSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/buildings.svg";
export const PuzzleSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/puzzle.svg";

export const CassSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/cass.svg";

export const RawdataSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/rawdata.svg";
export const MobilesSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/mobiles.svg";

export const ProfilebgSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/profilebg.svg";
export const BurgerPng =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/burger.svg";
export const NkIconSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/NkIcon.svg";
export const NeokredWhiteSvg =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/NeokredWhite.svg";
export const ProdProfileX =
  "https://neokredwebsite.s3.ap-south-1.amazonaws.com/svg/prod_profilex.svg";

const AppStoreSvg = appstore;
const GooglePlaySvg = googleplay;
const PhoneSvg1 = phone1;
const PhoneSvg2 = phone2;
const PhoneSvg3 = phone3;
const PhoneSvg4 = phone4;
const EKYC1SVG = ekyc1;
const POLICILOGO = PoliciLogo;
const MONEY = Money;
const NETWORKICON = NetworkIcon;
const POLICIICON = PoliciIcon;
const LAYOFFICON = LayoffIcon;
const FINANCEICON = FinanceIcon;
const PAYBILLICON = PayBillsIcon;
const EKYC2SVG = ekyc2;
const AadharSVG = aadhar;
const WhiteApplestore1 = WhiteApplestore;
const StorySvg = story;
const ScalableSvg = scalable;
const DocumentcodeSvg = documentcode;
const SecureSvg = secure;
const Girl = girl;
const Cshapes = Cshape;
const Product1 = product1;
const Product2 = product2;
const Product3 = product3;
const Product4 = product4;
const Product5 = product5;
const Product6 = product6;
const Product7 = product7;
const Product8 = product8;
const GroupPeople = grouppeople;
const GiftOn1 = gifton;
const Wallets = wallets;
const Cards = cards;
const Receive = recive;
const Send = send;
const Rewards = rewards;
const whatWeHave = blue_image_web;
const whatWeHaveMobile = blue_image_mobile;
const Icon_rupee = icon_rupee;
const Wallet_card_icon = wallet_card_icon;
const Tranx_card_icon = tansx_card_icon;
const ProgramSetUp_card_icon = programSetUp_card_icon;
const Safe_card_icon = safe_card_icon;
const Virtual_card_icon = virtual_card_icon;
const CollectBot_card_icon = collectBot_card_icon;
const Control_card_icon = control_card_icon;
const CardCycle_card_icon = cardCycle_card_icon;
const Settlement_card_icon = settllement_icon;
const CardLife_card_icon = cardLife_card_icon;
const Plateform_card_icon = platForm_card_icon;
const NeoBank_card_icon = neobank_card_icon;
const Fleetss_card_icon = fleets_card_icon;
const Corporate_card_icon = corporate_catrd_icon;
const Scalability_card_icon = scalability_card_icon;
const Flee_card_icon = flee_card_icon;
const Certification_card_icon = certification_card_icon;

const DataAiDash = dataaidash;
const Expense = expense;
const Mobiles = mobiles;
const CertifiedLogo = certified;
const Mobilekey = mobilekey;
const Keybg = keybg;
const Neokey = neokey;
const NeoStrap = neostrap;
const Neoloop = neoloop;
const Collectbotvan = collectbotvan;
const Dashboard = dashboard;
const Yesbank = yesbank;
const Giftonmobdash = giftonmobdash;
const Giftondash = giftondash;
const Tarun = tarun;
const Vivek = vivek;
const Harish = harish;
const Dipali = dipali;
const Rohith = rohith;
const HomeLogo = homelogo;
const Success = success;
const Glass = glass;
const CertificationBadge = certificationBadge;

export {
  Scalability_card_icon,
  Flee_card_icon,
  Certification_card_icon,
  Wallet_card_icon,
  Tranx_card_icon,
  ProgramSetUp_card_icon,
  Safe_card_icon,
  Virtual_card_icon,
  CollectBot_card_icon,
  Control_card_icon,
  CardCycle_card_icon,
  Settlement_card_icon,
  CardLife_card_icon,
  Corporate_card_icon,
  Fleetss_card_icon,
  NeoBank_card_icon,
  Plateform_card_icon,
  Product1,
  Product2,
  Product3,
  Product4,
  Product5,
  Product6,
  Product7,
  Product8,
  Icon_rupee,
  GroupPeople,
  AppStoreSvg,
  GooglePlaySvg,
  PhoneSvg1,
  PhoneSvg2,
  PhoneSvg3,
  PhoneSvg4,
  EKYC1SVG,
  EKYC2SVG,
  AadharSVG,
  StorySvg,
  WhiteApplestore1,
  ScalableSvg,
  DocumentcodeSvg,
  SecureSvg,
  Girl,
  Cshapes,
  GiftOn1,
  Wallets,
  Receive,
  Send,
  Rewards,
  Cards,
  whatWeHave,
  whatWeHaveMobile,
  DataAiDash,
  Expense,
  Mobiles,
  CertifiedLogo,
  Mobilekey,
  Keybg,
  Neokey,
  NeoStrap,
  Neoloop,
  Dashboard,
  Collectbotvan,
  Yesbank,
  Giftonmobdash,
  Giftondash,
  Tarun,
  Vivek,
  Dipali,
  Harish,
  Rohith,
  HomeLogo,
  Success,
  Glass,
  CertificationBadge,
  POLICILOGO,
  MONEY,
  POLICIICON,
  NETWORKICON,
  LAYOFFICON,
  FINANCEICON,
  PAYBILLICON,
};

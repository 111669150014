import React from "react";

function MainHomeComponent({
  leftcomponent,
  rightcomponent,
  leftStyle,
  rightStyle,
  style,
  reverse = false,
  width,
  height,
}) {
  return (
    <div
      style={{
        background: "white",
        width: "100%",
        padding: width > 640 ? "10px 20px" : "0px 12px",
        display: "flex",
        justifyContent: width > 640 ? "space-evenly" : "flex-end",
        alignItems: "center",
        boxSizing: "border-box",
        minHeight: width > 640 ? height * 0.7 : "100%",
        ...style,
      }}
      className={`rounded-none ${
        reverse ? "flex flex-col-reverse" : "flex flex-col"
      }  lg:flex-row lg:rounded-b-xl`}
    >
      <div
        style={{
          ...leftStyle,
        }}
        className="w-full px-10 lg:w-2/4 lg:px-12 "
      >
        {leftcomponent}
      </div>
      <div
        style={{
          ...rightStyle,
        }}
        className="w-full mt-8 flex flex-row justify-center items-center lg:w-2/4 lg:mt-0"
      >
        {rightcomponent}
      </div>
    </div>
  );
}
export default MainHomeComponent;

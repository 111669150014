import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import CardPageIndex from "../presentation/pages/cards";
import HomePageIndex from "../presentation/pages/home";
import GiftOn from "../presentation/pages/gifton";
import KycIndex from "../presentation/pages/kyc";
import LendxIndex from "../presentation/pages/lendx";
import ProfileX from "../presentation/pages/profilex";
import AboutIndex from "../presentation/pages/about";
import CollectbotIndex from "../presentation/pages/collectbot";
import Wearables from "../presentation/pages/wearables";
import ContactUs from "../presentation/pages/contact_us";
import DataAi from "../presentation/pages/dataai";
import LoginIndex from "../presentation/pages/login";
import Error from "../presentation/pages/Error";
import Terms from "../presentation/pages/footer_pages/Terms";
import Privacy from "../presentation/pages/footer_pages/Privacy";
import Cancellation from "../presentation/pages/footer_pages/Cancellation";
import Polici from "../presentation/pages/polici";

function RouteIndex() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/home" />} />
      <Route path="/home" element={<HomePageIndex />} />
      {/* <Route path="/cards" element={<CardPageIndex />} />
      <Route path="/gifton" element={<GiftOn />} /> */}
      <Route path="/kyc" element={<KycIndex />} />
      <Route path="/polici" element={<Polici />} />
      {/* <Route path="/lendx" element={<LendxIndex />} /> */}
      <Route path="/profilex" element={<ProfileX />} />
      <Route path="/aboutus" element={<AboutIndex />} />
      <Route path="/collectbot" element={<CollectbotIndex />} />
      <Route path="/wearables" element={<Wearables />} />
      <Route path="/contact-us" element={<ContactUs />} />
      {/* <Route path="/data-ai" element={<DataAi />} /> */}
      <Route path="/login" element={<LoginIndex />} />
      <Route path="/error" element={<Error />} />
      <Route path="/terms" element={<Terms/>}/>
      <Route path="/privacy-policy" element={<Privacy/>}/>
      <Route path="/cancellation-refund-policy" element={<Cancellation/>}/>
      
      {/* <Route path="/*" element={<Navigate to="/error" />} /> */}
    </Routes>
  );
}

export default RouteIndex;

import React from "react";
// import { PRIMARY_COLOR } from "../colorManager";

function MainHomeComponent({
  leftcomponent,
  rightcomponent,
  leftStyle,
  rightStyle,
  style,
  reverse = false,
  width,
  height,
}) {
  return (
    <div
      style={{
        width: "100%",
        padding: width > 640 ? "10px 20px" : "0px 10px",
        display: "flex",
        justifyContent: width > 640 ? "center" : "flex-end",
        alignItems: "center",
        boxSizing: "border-box",
        height: width > 640 ? height * 0.7 : "100%",
        ...style,
      }}
      className={`rounded-none ${
        reverse ? "flex flex-col-reverse" : "flex flex-col"
      }  lg:flex-row lg:rounded-b-xl`}
    >
      <div
        style={{
          ...leftStyle,
          boxSizing: "border-box",
        }}
        className="w-full px-4 lg:w-2/4 lg:px-16 sm:px-0"
      >
        {leftcomponent}
      </div>
      <div
        style={{
          ...rightStyle,
          boxSizing: "border-box",
        }}
        className="w-full flex flex-row justify-center md: items-center lg:w-2/4"
      >
        {rightcomponent}
      </div>
    </div>
  );
}

export default MainHomeComponent;

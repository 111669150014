import React from "react";
import { useNavigate } from "react-router";
import {
  GroupPeople, whatWeHave, whatWeHaveMobile,
} from "../../../utility/assetsManager";
import { BLACK, WHITE } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomButton from "../../components/custom_button";
import WebImage from "../../components/blue_image/webImage";
import MobileImage from "../../components/blue_image/mobileImage";

function Expert() {
  const { width } = ResizeListener();
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
      }}
      className="flex flex-col"
    >
      <div className="flex flex-col  lg:flex-row justify-between mb-4 lg:mb-14 ">
        <div className="flex justify-start px-8   mb-4 lg:mb-0 lg:px-16 items-start flex-col end w-full lg:w-6/12 py-4">
          <p style={{ ...styles.para }} className=" text-base lg:text-2xl  ">
            It’s all about you - and that <br />
            makes us Happy
          </p>
          <p
            className=" mt-4 lg:text-4xl py-5"
            style={{ ...styles.ready, fontWeight: 800,fontSize:"30px" }}
          >
            Embedded
            <br /> Finance <br />
            Experiences -
            <br /> delivered seamlessly
          </p>
        </div>
        <div
          style={{}}
          className="flex items-start lg:items-end  lg:justify-end w-full lg:w-6/12 "
        >
          <img
            src={GroupPeople}
            style={{
              minWidth: width > 645 ? 729 : 330,
              objectFit: "contain",
              maxWidth: 580,
              marginLeft: width > 645 ? 0 : 20,
            }}
            alt="groupPeople"
          />
        </div>
      </div>

      <div
        className="flex flex-col justify-around	 items-center lg:items-start  mt-14 lg:mt-0 lg:flex-row "
        style={{
          margin: "40px 0px",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "start",
          }}
          className=" lg:w-4/12 md:w-4/12 sm:w-7"
        >

          {width > 645 ? <img src={whatWeHave} alt="whatWeHave" /> : <img src={whatWeHaveMobile} alt="whatWeHaveMobile" />}

        </div>
        <div
          style={{
            width: width > 1024 ? "29.1%" : "100%",
            boxSizing: "border-box",
            marginRight: width > 645 && width > 1240 ? 250 : 0,
            marginTop: width > 1050 ? "10px" : width > 645 ? "60px" : "0px",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",

          }}
        >
          <div
            style={{
              width: "100%",
              color: "#6C6B6B",
              fontWeight: "400",
              marginRight: "15%",
              padding: width > 1284 ? "0px" : width > 645 ? "30px" : "40px",
            }}
          >
            <p
              style={{
                fontWeight: 500,
                color: "black",
                lineHeight: "1.2",
                fontFamily: "Satoshi",
                width: "100%",
                backgroundColor: "",
                fontSize: width > 645 ? "18px" : "22px",
              }}
            >
              The Neokred platform is a new approach for any company (for
              example, fintech, sports club, retailer, brand) wishing to harness
              the power of embedded finance experiences for consumer engagement,
              rewards, data and revenue.
            </p>
            <p
              style={{
                fontFamily: "Satoshi",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "1.3",
                margin: "30px 0 10px 0",
                color: "#6C6B6B",
              }}
            >
              Finance capabilities, such as digital wallets, payments, rewards,
              loyalty points or cards are simple for us to create and combine
              into an embedded finance experience that integrates directly and
              seamlessly into your brand and digital journey.
            </p>
            <p
              style={{
                fontFamily: "Satoshi",
                fontSize: "16px",
                lineHeight: "1.3",
                margin: "30px 0 10px 0",
                fontWeight: "400",
                color: "#6C6B6B",
              }}
            >
              Clunky finance white labels and co-branded solutions are a thing
              of the past with the Neokred platform. We respect that your
              customer journey is super important to you, which is why we
              provide powerful APIs that keep us behind the scenes and hero of
              your digital journey.
            </p>
            <p
              style={{
                fontFamily: "Satoshi",
                fontSize: "16px",
                lineHeight: "1.3",
                margin: "30px 0 10px 0px",
                fontWeight: "400",
                color: "#6C6B6B",
              }}
            >
              If you have an embedded finance use-case, it is highly likely that
              the Neokred platform has the APIs for you to build it and realize
              your ambition.
            </p>
          </div>

          <CustomButton
            title="Talk to an Expert"
            style={{
              marginTop: width > 645 ? "30px" : "10px",
              width: "190px",
              height: "50px",
              marginLeft: width > 1284 ? "0px" : width > 645 ? "30px" : "40px",
            }}
            onClick={() => navigate("/contact-us")}
          />
        </div>{" "}
      </div>
    </div>
  );
}

const styles = {
  para1: {
    fontFamily: "Satoshi",
    fontSize: "29px",
    fontWeight: 700,
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    color: BLACK,
  },
  icon: {
    color: WHITE,
    fontWeight: "400px",
    marginTop: "20px",
  },
};

export default Expert;
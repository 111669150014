import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { NavBarOptionsMobile } from "../data";
import { PrivateLink } from "../presentation/components/PrivateLink";
import { BurgerPng, NkIconSvg } from "../utility/assetsManager";
import ResizeListener from "../utility/ResizeListener";
import "./index.css";
function MobileNav() {
  const [onClickNav, setOnClickNav] = useState(false);
  const { width, height } = ResizeListener();
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          backgroundColor: "#252734",
          padding: "10px 30px",
          boxSizing: "border-box",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
        className="w-full"
      >
        <div
          className="w-full item-center  flex flex-row justify-between"
          style={{
            height: "100%",
          }}
        >
          <img
            style={{
              objectFit: "contain",
              width: 45,
              height: 40,
            }}
            src={NkIconSvg}
            onClick={() => {
              navigate("/home");
              setOnClickNav(false);
            }}
            alt="nkiconnav"
          />

          {onClickNav ? (
            <div
              style={{
                height: "100%",
              }}
              className="flex flex-row justify-center items-center"
            >
              <IoMdClose
                style={{
                  objectFit: "contain",
                  cursor: "pointer",
                  color: "#FF9800",
                }}
                onClick={() => {
                  setOnClickNav(false);
                }}
                size={30}
              />
            </div>
          ) : (
            <img
              src={BurgerPng}
              style={{
                objectFit: "contain",
                width: 25,
                cursor: "pointer",
              }}
              onClick={() => {
                setOnClickNav(true);
              }}
              alt="burger"
            />
          )}
        </div>
      </div>

      <div
        style={{
          height,
          width,
          position: "fixed",
          top: 50,
          zIndex: 100,
          overflow: "hidden",
          backgroundColor: "#252734",
        }}
        // id="menu"
        className={onClickNav ? "drawer-div" : "drawer-div-close"}
      >
        <div
          style={{
            width: "100%",
          }}
          className="flex flex-col divide-y divide-[#737272]/10 px-4"
          id={onClickNav ? "drawer-navs" : "drawer-navs-close"}
        >
          {NavBarOptionsMobile &&
            NavBarOptionsMobile?.map((nav, index) => (
              <PrivateLink
                to={nav.to}
                title={nav.name}
                isLink={nav.isLink}
                onClick={() => {
                  // setOpenDrawer(false);
                  setOnClickNav(false);
                }}
                textStyles={{
                  fontSize: 16,
                  width: "100%",
                }}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default MobileNav;

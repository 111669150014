import React from "react";
import { useLocation } from "react-router";
import Footer1 from "../presentation/components/navbarandfooter/Footer1";
import ResizeListener from "../utility/ResizeListener";
import MobileNav from "./MobileNav";
import TopNavbar from "./TopNavbar";
function Layout({ children }) {
  const { width,  } = ResizeListener();
  const location = useLocation();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
        }}
      >
        {width > 900 ? <TopNavbar /> : <MobileNav />}
        <div
          style={{
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          {children}
        </div>
      </div>
      <Footer1 />
    </div>
  );
}

export default Layout;

import React, { useEffect, useState } from "react";
import { SliderData } from "../../../data/freezedData";
import "./slider.css";

const UspSlider = ({ width }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = SliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 3000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const slideChange = (data) => {
    setCurrentSlide(data);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <div
      className="slider1"
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        // minHeight: 400,
        minHeight: width > 640 ? "430px" : "550px",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "83%",
        }}
      //minHeight: 100,
      >
        {SliderData.map((slide, index) => {
          return (
            <div
              className={index === currentSlide ? "slide1 current1" : "slide1"}
              key={index}
              style={{
                position: "unset",
              }}
            >
              {index === currentSlide && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    position: "relative",
                    marginLeft: "10px",
                  }}
                >
                  {/* <slide.image className="slide_img" /> */}
                  <div className="slide_img">
                    <p
                      className="text-3xl"
                      style={{
                        color: "#1E2234",
                        marginTop: "20px",
                        fontWeight: "700",
                        fontFamily: "Satoshi",
                      }}
                    >
                      At Neokred we <br /> believe...
                    </p>
                    <p
                      style={{
                        color: "#1E2234",
                        marginTop: "10px",
                        maxWidth: "400px",
                        fontWeight: "500",
                        fontFamily: "Satoshi",
                        fontStyle: "normal",
                        fontSize: "20px",
                      }}
                    >
                      {slide.title}
                    </p>
                    <p
                      style={{
                        color: "#38369C",
                        fontSize: "15px",
                        marginTop: "10px",
                        maxWidth: "400px",
                        fontFamily: "Satoshi",
                        fontStyle: "normal",
                        fontWeight: 500,
                      }}
                    >
                      {slide.description1}
                    </p>
                    <p
                      style={{
                        color: "#6C6B6B",
                        fontSize: "15px",
                        marginTop: "10px",
                        maxWidth: "400px",
                        fontFamily: "Satoshi",
                        fontStyle: "normal",
                        fontWeight: 500,
                      }}
                    >
                      {slide.description}
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          marginTop: 20,
          bottom: width>645?"0px":"26px",
          position: "absolute",
          paddingBottom: width > 645 ? "0px" : "25px"
        }}
      >
        <div
          style={{
            width: 8,
            height: 8,
            backgroundColor: currentSlide === 0 ? "#949494" : "#E4E4E8",
            borderRadius: 10,
            marginRight: 5,
            cursor: "pointer",
          }}
          onClick={() => slideChange(0)}
        />
        <div
          style={{
            width: 8,
            height: 8,
            backgroundColor: currentSlide === 1 ? "#949494" : "#E4E4E8",
            borderRadius: 10,
            marginRight: 5,
            cursor: "pointer",
          }}
          onClick={() => slideChange(1)}
        />
        <div
          style={{
            width: 8,
            height: 8,
            backgroundColor: currentSlide === 2 ? "#949494" : "#E4E4E8",
            borderRadius: 10,
            marginRight: 5,
            cursor: "pointer",
          }}
          onClick={() => slideChange(2)}
        />
      </div>
    </div>
  );
};

export default UspSlider;

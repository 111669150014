import React from "react";
import { BLACK, WHITE } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import { Container2 } from "../../pages/about";

const CustomAchievements =()=>{
    let {width}=ResizeListener()
    return (
      <div
        style={{
          backgroundColor: " #1E2234",
          padding: "100px 20px ",

          textAlign: "center",
        }}
        className="flex flex-col justify-center items-center "
      >
        <p
          style={{
            ...styles.ready,
            fontSize: 30,
            fontWeight: 800,
            
          }}
        >
          Our Achievement
        </p>
        <p
          style={{
            minWidth: width > 645 ? 287 : 300,
            maxWidth: width > 645 ? 302 : 600,
            color: "#CBCACA",
            margin: width > 645 ? "15px 0px 30px 0px" : "30px 0px",
            justifyContent:width>645?"ceneter":"start"
          }}
        >
          Our fintech solutions have not only won hearts and people, but also
          various awards.
        </p>
        <div className="flex flex-wrap items-center justify-center">
          <Container2
            title="REVOPRENEUR"
            subtitle="Top 10 Revolutionary Entrepreneurs 2022"
            textColor={WHITE}
            textstyle={{}}
            textstyle2={{
              marginTop: 6,
              fontWeight: "400",
            }}
            fillColor={WHITE}
          />
          <Container2
            title="THE CEO STORY"
            subtitle="Company of the Year 2021"
            textColor={WHITE}
            textstyle2={{
              marginTop: 6,
              fontWeight: "400",
            }}
            fillColor={WHITE}
          />
          <Container2
            title="ENTREPRENEUR."
            subtitle="Young Entrepreneur of the Year 2021"
            textColor={WHITE}
            textstyle2={{
              marginTop: 6,
              fontWeight: "400",
            }}
            fillColor={WHITE}
          />
        </div>
      </div>
    );
}
export default CustomAchievements

const styles = {
  ready: {
    fontSize: 32,
    background: "linear-gradient(90deg, #4947B8 , #FF9700)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",

    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    color: BLACK,
  },

  title1: {
    color: "#FF9800",
    fontSize: 50,
    fontWeight: "bold",
  },
  blackFonts: {
    fontWeight: "800",
    fontSize: "26px",
    marginLeft: "0px",
  },

  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },

  text1: {
    color: "white",
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",

    // transform: "scaleX(1.1)",
  },
  imageStyle: {
    width: "150px",
    height: "30px",
    objectFit: "contain",
  },
};
import React from "react";
import { Error1, Look_like, OH } from "../../../utility/assetsManager";

const Error = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <img style={{ margin: "auto", paddingBottom: "10px" }} src={Error1} alt="error1" />
      <img style={{ margin: "auto" }} src={OH} alt="oh" />
      <img style={{ margin: "auto", padding: "20px" }} src={Look_like} alt="looklike" />
      <div
        style={{
          margin: "auto",
          height: "40px",
          width: "170px",
          borderRadius: "8px",
          marginTop: "12px",
          border: "1px solid red",
          padding: "5px 0 0 60px",
          marginBottom: "30px",
          backgroundColor: "#F59300",
          color: "white",
        }}
      >
        <a href="/home">
          <button>Home</button>
        </a>
      </div>
    </div>
  );
};

export default Error;

import React from "react";

const Cookies = ({ onClick  , onClickReject}) => {
  return (
    <div className="h-auto  md:h-[175px] border border-[#f1f5f9]  p-5">
      <p className="text-[#000000] text-14 font-400 w-full text-center">
        We use cookies to personalise content and ads, to provide social media
        features and to analyse our traffic. We also disclose information about
        your use of our site with our social media, advertising and analytics
        partners. Additional details are available in ourCookie Policy.
      </p>
      <div className="flex gap-4 justify-center items-center mt-3">

         <button
          className=" h-[40px] rounded-md bg-[#e0f2fe] mt-3 flex items-center justify-center p-2 text-[14px]"
          style={{
            color:"red"
          }}
          onClick={onClickReject}
        >
          Reject 
        </button>
        <button
          className=" h-[40px] rounded-md bg-[#e0f2fe] mt-3 flex items-center justify-center p-2 text-[14px]"
          onClick={onClick}
        >
          Accept All Cookies
        </button>

       
      </div>
    </div>
  );
};

export default Cookies;

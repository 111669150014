import React from "react";
import { useLocation, useNavigate } from "react-router";
import { NavBarOptionsWeb } from "../../../data";
import {
  Yesbank,
  CertifiedLogo,
  NeokredWhiteSvg,
  CertificationBadge,
} from "../../../utility/assetsManager";
import { BUTTON_COLOR } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomText from "../custom_text";
import { PrivateLink } from "../PrivateLink";
import "./styles/footer.css";
import { Link } from "react-router-dom";
const Footer1 = () => {
  const navigate = useNavigate();
  const { width } = ResizeListener();
  const location = useLocation();
  return (
    <>
      <div
        style={{
          background: "#1E2234",
          display: "flex",
          justifyContent: "space-around",
          height: width < 900 ? "150vh" : "95vh",
          width: "100%",
        }}
        className="w-full py-14 flex flex-col px-12 items-center"
      >
        <div className="flex w-full lg:w-3/4 flex-col md:flex-row md:items-center md:justify-between ">
          <div
            style={{
              paddingTop: "70px",
            }}
          >
            <img
              src={NeokredWhiteSvg}
              style={{ height: "60px", width: "190px" }}
              alt="neokred-logo-white"
            />
            <img
              src={CertifiedLogo}
              style={{ height: "50px", width: "167px", marginTop: "30px" }}
              alt="CertifiedLogo"
            />
            <CustomText
              style={{
                fontSize: "16px",
                color: "#DFDFDF",
                fontFamily: "Satoshi",
                marginTop: "20px",
              }}
            >
              In partnership with
            </CustomText>
            <img
              src={Yesbank}
              style={{
                height: width > 645 ? "54px" : "38px",
                width: width > 645 ? "296" : "209px",
                marginTop: "5px",
              }}
              alt="bank-logo"
            />
            <CustomText
              style={{
                fontSize: "16px",
                color: "#DFDFDF",
                fontFamily: "Satoshi",
                marginTop: "20px",
              }}
            >
              We are Great Place to Work
            </CustomText>
            <img
              src={CertificationBadge}
              style={{
                height: width > 645 ? "54px" : "45px",
                width: width > 645 ? "55" : "35px",
                marginTop: "15px",
              }}
              alt="certification"
            />
          </div>
          <div className="flex mt-10 flex-row md:flex-col md:mt-4 mr-100">
            <div>
              <CustomText
                style={{
                  background: "linear-gradient(to right, #4947B8 , #FF9700)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "900",
                  fontFamily: "Satoshi",
                  fontSize: "18px",
                  width: "160px",
                  margin: "0px 5px 10px 2px",
                }}
              >
                Products
              </CustomText>
              <div
                style={{
                  maxWidth: "600px",
                }}
                id="web-nav"
                className="grid gap-y-5 lg:grid-rows-2 lg:grid-cols-4 lg:gap-16 lg:gap-y-4 mt-5 md:grid-rows-2 md:grid-cols-3 md:gap-10"
              >
                {NavBarOptionsWeb &&
                  NavBarOptionsWeb?.map((obj, index) => (
                    <div
                      style={{
                        width: "auto",
                        cursor: "pointer",
                      }}
                      className="flex items-center"
                      key={index}
                    >
                      <PrivateLink
                        to={obj.to}
                        isLink={obj.isLink}
                        title={obj.name}
                        textColor={"white"}
                        style={{
                          padding: 0,
                          margin: 0,
                        }}
                        textStyles={{
                          fontSize: 14,
                          color:
                            location.pathname === obj.to
                              ? BUTTON_COLOR
                              : "white",
                        }}
                      ></PrivateLink>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className="md:mt-5">
                <div
                  style={{
                    cursor: "pointer",
                    marginBottom: width < 648 ? "22px" : "30px",
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/neokred/jobs/"
                    )
                  }
                >
                  <CustomText
                    style={{
                      background:
                        "linear-gradient(to right, #4947B8 , #FF9700)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: "900",
                      fontFamily: "Satoshi",
                      fontSize: "18px",
                      width: "150px",
                    }}
                  >
                    We are Hiring
                  </CustomText>
                </div>
                <div className="flex flex-col gap-y-6 md:gap-y-0  md:flex-row  ">
                  <Link
                    style={{
                      color: "#F5F5F5",
                      fontSize: "12px",
                      fontFamily: "Satoshi",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    to="/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    to="/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#F5F5F5",
                      fontSize: "12px",
                      fontFamily: "Satoshi",
                      textDecoration: "underline",
                      marginLeft: width < 768 ? "0px" : "15px",
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to="/cancellation-refund-policy"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#F5F5F5",
                      fontSize: "12px",
                      fontFamily: "Satoshi",
                      textDecoration: "underline",
                      marginLeft: width < 768 ? "0px" : "15px",
                      cursor: "pointer",
                    }}
                  >
                    Cancellation & Refund Policy
                  </Link>
                </div>
                <p
                  style={{
                    fontFamily: "Satoshi",
                    fontStyle: "normal",
                    fontWeight: "465",
                    color: "#FDFDFD",
                    size: "1px",
                    fontSize: 10,
                    alignItems: "left",
                  }}
                  className="hidden lg:flex flex-col lg:justify-items-stretch justify-end mt-5 "
                >
                  Copyright © 2022 NEOKRED TECHNOLOGIES PRIVATE LIMITED
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-3/4 justify-between mt-7 flex-col ">
          <CustomText
            style={{
              color: "#F5F5F5",
              fontSize: "16px",
              fontFamily: "Satoshi",
              fontWeight: "bold",
            }}
          >
            Follow us on:
          </CustomText>
          <div className="flex gap-y-4 gap-x-2 md:gap-y-1 md:gap-x-1 flex-wrap justify-between mt-5 items-center">
            <a
              className="color linkldn"
              href="https://www.linkedin.com/company/neokred/mycompany/verification"
              target={"_blank"}
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              className="color insta"
              href="https://www.instagram.com/neokredtech/"
              target={"_blank"}
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              className="color twitter"
              href="https://twitter.com/neokredtech"
              target={"_blank"}
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              className="color facebook"
              href="https://www.facebook.com/neokredtech"
              target={"_blank"}
              rel="noreferrer"
            >
              Facebook
            </a>
            <a
              className="color youtube"
              href="https://www.youtube.com/channel/UCM3Mhah7zEO5L0gaGrRGu4Q"
              target={"_blank"}
              rel="noreferrer"
            >
              YouTube
            </a>
            <p
              style={{
                fontFamily: "Satoshi",
                fontStyle: "normal",
                fontWeight: "200",
                color: "#FDFDFD",
                fontSize: "14px",
                margin: "45px 0px 5px",
              }}
              className="w-full text-left text-base lg:hidden sm:flex flex-col "
            >
              Copyright © 2022 NEOKRED TECHNOLOGIES PRIVATE LIMITED
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer1;

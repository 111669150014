import React from "react";
import SVG from "react-inlinesvg";
import {
  arrowMarkUp,
  blackA,
  EllipseSvg,
  Giftonmobdash,
  Giftondash,
  GooglePlaySvg,
  WhiteApplestore1,
} from "../../../utility/assetsManager";
import { BLACK, DARK_BLUE, LIGHT_FONT } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomBrilliance from "../../components/custom_brilliance";
import CustomButton from "../../components/custom_button";
import MainHomeComponent from "../../components/ui_components/MainHomeContainer";

const LeftComponent = () => {
  const { width } = ResizeListener();
  return (
    <div
      style={{
        textAlign: "start",
        height: width < 645 ? "80vh" : "",
      }}
      className="flex flex-col items-right lg:items-start justify-center lg:px-20 py-3"
    >
      <p style={{ ...styles.ready, paddingBottom: "10px" }} className="text-3xl md:text-5xl">
        Give the gift of their choice. Keep gifting simple!
      </p>

      <p
        style={{ ...styles.para, margin: "20px 0px ", fontSize: 19 }}
        className="w-full text-base	 lg:w-10.2/12 lg:text-xl"
      >
        Choose from our wide range of gift cards curated for every occasion for
        your loved ones to build lasting relationships.
      </p>
      <div
        style={{
          marginTop: 10,
        }}
      >
        <div>
          <p
            style={{
              ...styles.para,
              margin: "0px 40px 0px 0px",
              // padding: "60px 0px 0px 0",
            }}
          >
            Download GiftOn Consumer App
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: width > 640 ? "row" : "column",
          // margin: "1px 10px 0 35px",
          width: "80%",
        }}
      >
        {/* <GooglePlaySvg
          fill="white"
          style={{
            width: "170px",
            height: "80px",
            objectFit: "contain",
            minWidth: 100,
            margin: "0 60 0 0",
          }}
        /> */}
        <a href={"https://play.google.com/store/apps/details?id=com.gift.customer"}>
          <SVG
            src={GooglePlaySvg}
            fill="white"
            style={{
              width: "170px",
              height: "80px",
              objectFit: "contain",
              minWidth: 100,
              margin: "0 50 0 0",
            }}
          />
        </a>
        <a href={"https://apps.apple.com/us/app/gifton/id1642515249"}  >
          <WhiteApplestore1
            fill="white"
            width="150px"
            height="80px"
            objectFit="contain"
            marginLeft="40px"
            // paddingTop={width > 640 ? "" : "-160px"}
            minWidth={100}
          //style={{ paddingLeft: "30px" }}
          />
        </a>
      </div>
    </div>
  );
};

const LeftComponent1 = () => {
  const { width } = ResizeListener
  return (
    <div
      className="mb-10 lg:mb-0"
      style={{ padding: "10px 0 6px 0px ", marginLeft: "2%", }}
    >
      <p
        style={{ ...styles.ready, paddingBottom: "15px", paddingTop: "30" }}
        className="text-3xl lg:text-3xl"
      >
        Gifting is as simple as ABC
      </p>

      <p
        style={{ ...styles.para }}
        className="w-full  text-sm	 lg:w-3/4 lg:text-base"
      >
        We have created a clean and better dashboard for you to send gift cards
        to anyone you want.
      </p>

      <div
        style={{
          marginTop: 20,
        }}
      >

        <CustomButton
          title="Sign Up"
          onClick={() => { window.open("https://corporate.baink.club/register") }}
          icon={
            <img
              src={arrowMarkUp}
              style={{
                height: width > 645 ? "12px" : "9px",
                width: width > 645 ? "12px" : "9px",
                marginLeft: "-2px",
              }}
              alt="arrowMarkUphome"
            />
          }
          style={{
            marginTop: 20,
            padding: width > 645 ? "10px 20px" : "5px 15px",
          }}
        />
      </div>
    </div>
  );
};

const LeftComponent2 = ({ width }) => {
  return (
    <div style={{ height: "100%", marginBottom: width > 645 ? 100 : 20 }}>
      <p
        style={{ ...styles.ready, margin: "20px 3.3px" }}
        className="text-2xl lg:text-3xl"
      >
        Dedicated app for employees
      </p>
      <p
        style={{ ...styles.para, color: "#6C6B6B", padding: "10px 40px 0px 6px" }}
        className="text-s md:text-xl"
      >
        A clean UI experience for your employees as well.
      </p>
      <div
        style={{
          marginTop: 40,
        }}
      >
        <ul style={styles.liStyles}>
          <li className="text-base lg:text-md ">
            Claim gift card through the app
          </li>
          <li className="text-base lg:text-md ">
            Switch between your gift cards hassle-free
          </li>
          <li className="text-base lg:text-md ">
            Employees can also send gift card to their loved ones
          </li>
          <li className="text-base lg:text-md ">
            Avail exciting coupons curated for the users{" "}
          </li>
        </ul>
      </div>

      <div
        style={{
          marginTop: 10,
        }}
      >
        <div>
          <p
            style={{
              ...styles.para,
              margin: width > 645 ? "40px 40px 0px 0px" : "0px 40px 0px 0px",
              color: "#6c6b6b"
              // padding: "60px 0px 0px 0",
            }}
          >
            Download GiftOn Consumer App
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: width > 640 ? "row" : "column",
          // margin: "1px 10px 0 35px",
          width: "80%",
        }}
      >
        {/* <GooglePlaySvg
          fill="white"
          style={{
            width: "170px",
            height: "80px",
            objectFit: "contain",
            minWidth: 100,
            margin: "0 60 0 0",
          }}
        /> */}
        <a href={"https://play.google.com/store/apps/details?id=com.gift.customer"}>
          <SVG
            src={GooglePlaySvg}
            fill="black"
            style={{
              width: "170px",
              height: "80px",
              objectFit: "contain",
              minWidth: 100,
              margin: "0 50 0 0",

            }}
          />
        </a>
        <div onClick={() => { window.open("https://apps.apple.com/us/app/gifton/id1642515249") }}  >
          <img
            style={{
              // maxWidth: 190,
              objectFit: "contain",
              margin: "15px 0px",
              width: width > 645 ? 150 : 150,
              height: 50,
              paddingTop: "1px",
              cursor: "pointer"
            }}
            src={blackA}
            alt="wearables-appstore"
          />
        </div>
      </div>
    </div>
  );
};

const RightComponent1 = ({ width }) => {
  return (
    <div
      style={{
        height: "auto",
        alignSelf: "center",
        maxWidth: "500px",
        margin: "0 20px 0 20px",
      }}
    >
      <img
        src=
        {Giftondash}
        style={{
          display: "flex",
          objectFit: "contain",
          minWidth: 350,
          width: width * 0.45,
          maxWidth: 500,
          marginTop: "30px",
        }}
        alt="gifton-portal" />
    </div>
  );
};

const RightComponent2 = ({ width }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <img
        src={Giftonmobdash}
        style={{
          objectFit: "contain",
          minWidth: 320,
          width: width * 0.45,
          maxWidth: 500,
          marginTop: width < 645 ? "" : 100,
          marginRight: "30px",
        }}
        alt="Giftonmobdash"
      />
    </div>
  );
};

function GiftOn() {
  const { width, height } = ResizeListener();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div>
        <MainHomeComponent
          leftcomponent={<LeftComponent />}
          // rightcomponent={<RightComponent width={width}/>}
          leftStyle={{ width: "100%" }}
          style={{
            backgroundColor: DARK_BLUE,
            width: "100%",
            height: "100%",
            borderRadius: 0,
            // backgroundPosition: width > 600 ? "85% 45%" : "30% 10%",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: width > 800 ? "20%" : "50%",
            // padding: "100px 0px ",
            backgroundImage: `url(${EllipseSvg})`,
            backgroundPosition: width > 640 ? "90% 25%" : "start",
            backgroundRepeat: "no-repeat",
            backgroundSize: "270px",
            padding: "30px 0px",
          }}
          height={height}
        />
      </div>

      <div>
        <iframe
          src="https://drive.google.com/file/d/1XC1GRTejqXoV9I4UFle7xEBMx-YPHESW/preview"
          width="100%"
          height="480"
          allow="autoplay"
          title="Gift-on Promo"
        ></iframe>
      </div>

      <div>
        <MainHomeComponent
          leftcomponent={<LeftComponent1 />}
          rightcomponent={<RightComponent1 width={width} />}
          style={{
            backgroundColor: DARK_BLUE,
            width: "100%",
            height: "100%",
            borderRadius: 0,
            padding: "30px 0px",
            backgroundImage: `url(${EllipseSvg})`,
            //backgroundPosition: "left",
            backgroundPositionX: "270px",
            backgroundPositionY: "130px",
            backgroundRepeat: "no-repeat",
            paddingTop: "70px",
            backgroundSize: width < 1025 ? "30%" : "20%",
            marginRight: "50px",
          }}
          reverse={false}

        />

      </div>
      <div>
        <MainHomeComponent
          leftcomponent={<LeftComponent2 width={width} />}
          rightcomponent={<RightComponent2 width={width} />}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
            // padding: "30px 0px",
            backgroundColor: "#FFF6EF",
            // padding: "70px 0px 0px 0px",
            padding: 0,
            margin: 0,
            alignItems: "flex-end",
          }}
          rightStyle={{
            padding: 0,
            margin: 0,
          }}
        />
      </div>
      <CustomBrilliance />
    </div>
  );
}
export default GiftOn;

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    // lineHeight: 1.0,
    //margin: "15px",
    // padding: "5px 0px",
  },
  ready1: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    lineHeight: 1.1,
    // padding: "5px 0px",
    // margin: "-10px 0",
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "400",
    color: LIGHT_FONT,
    marginTop: "10px",
  },

  container: {
    backgroundColor: DARK_BLUE,
    width: "100%",
    height: "100%",
  },
  liStyles: {
    listStyleType: "disc",
    fontSize: "10px",
    color: BLACK,
    fontWeight: "400",
    margin: "20px 0 0 10px",
    minWidth: 300,
    maxWidth: 450,
  },
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

import React from "react";
import { BLACK } from "../../../utility/colorManager";

function CustomText({ children, style, className }) {
  return (
    <p
      style={{
        color: BLACK,
        padding: 0,
        margin: 0,
        fontFamily: "Satoshi",
        ...style,
      }}
      className={className}
    >
      {children}
    </p>
  );
}

export default CustomText;

import React from "react";
import {
  Collectbotvan,
  Dashboard,
  EllipseSvg,
} from "../../../utility/assetsManager";
import {
  BLACK,
  DARK_BLUE,
  LIGHT_FONT,
  WHITE,
} from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomBrilliance from "../../components/custom_brilliance";
import MainHomeComponent from "../../components/Main_component";

const LeftComponent = ({ width }) => {
  return (
    <div
      style={{
        margin: "10px 0px",
        textAlign: "center",
        height: width > 645 ? "100%" : "65vh",
      }}
      className="flex flex-col items-center justify-center"
    >
      <p style={styles.ready} className="text-3xl md:text-5xl">
        Ease Up! <br />
        Your expense report is ready
      </p>

      <p
        style={{ ...styles.para, margin: "8px 0px", fontSize: width < 645 ? "" : 24 }}
        className="w-full text-center text-base	 lg:w-3/4 lg:text-xl"
      >
        Generate your error free monthly expense report swiftly on a single
        click.
      </p>
    </div>
  );
};

const LeftComponent3 = () => {
  return (
    <div style={{}}>
      <p
        style={{ ...styles.ready, fontSize: 30, margin: "1px 60px 20px 3px", }}
        className="text-xl lg:text-3xl"
      >
        Expand your business, ease your workload with our Collectbot
      </p>

      <p
        style={{ ...styles.para, margin: "30px 100px 0px 3px", color: "#6C6B6B" }}
        className="text-1xl lg:text-xl"
      >
        Create and manage the virtual account dynamically.
      </p>

      <div
        style={{
          marginTop: 30,
        }}
      >
        <ul style={{ ...styles.liStyles, color: BLACK, marginLeft: "20px" }}>
          <li className="text-base lg:text-1xl ">Reconcile automatically</li>
          <li className="text-base lg:text-1xl ">
            Instant account setup with easy KYC
          </li>
          <li className="text-base lg:text-1xl ">
            Automatic and transparent financial integration
          </li>
          <li className="text-base lg:text-1xl ">Robust customer onboarding</li>
          <li className="text-base lg:text-1xl ">
            Receive and send money from anyone via IMPS, RTGS, NEFT, or UPI
          </li>
        </ul>
      </div>
    </div>
  );
};

const LeftComponent4 = () => {
  return (
    <div style={{}}>
      <p
        style={{ ...styles.ready, marginBottom: "20px" }}
        className="text-4xl lg:text-5xl"
      >
        Quick peek at your account activity
      </p>

      <p
        style={{ ...styles.para, margin: "5px 70px 0px 3px", color: "#CBCACA", }}
        className="text-1xl lg:text-1xl"
      >
        Get complete insight of all your transaction details instantly.
      </p>

      <div
        style={{
          marginTop: 40,
        }}
      >
        <ul style={{ ...styles.liStyles, marginLeft: "28px" }}>
          <li className="text-base lg:text-1xl ">Enhanced fund management</li>
          <li className="text-base lg:text-1xl ">
            Accept multiple transactions in a single go
          </li>
          <li className="text-base lg:text-1xl ">
            Eases reconciliation, payments, & disbursals
          </li>
          <li className="text-base lg:text-1xl ">
            Simple, streamlined, & synced account structure
          </li>
        </ul>
      </div>
    </div>
  );
};

const RightComponent3 = ({ width }) => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <img
        src={Collectbotvan}
        style={{
          minWidth: 320,
          objectFit: "contain",
          width: width * 0.3,
          maxWidth: 500,
        }}
        alt="Collectbotvan"
      />
    </div>
  );
};

const RightComponent4 = ({ width }) => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
      className="mt-14 lg:mt-0"
    >
      <img
        src={Dashboard}
        style={{
          objectFit: "contain",
          minWidth: 380,
          width: width * 0.3,
          maxWidth: 500,
        }}
        alt="Dashboard"
      />
    </div>
  );
};

function CollectbotIndex() {
  const { width, height } = ResizeListener();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: width > 640 ? "10px 20px" : "0px 10px",
          display: "flex",
          justifyContent: width > 640 ? "center" : "flex-end",
          alignItems: "center",
          boxSizing: "border-box",
          height: width > 640 ? height * 0.7 : "100%",
          backgroundColor: DARK_BLUE,
          backgroundImage: `url(${EllipseSvg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "300px",
        }}
      >
        <LeftComponent width={width} />
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: DARK_BLUE,
        }}
      >
        <iframe
          src="https://drive.google.com/file/d/1Hxi_RlzhZfWJ4HAXZqN3MuC2zAhy95rE/preview"
          width="100%"
          height="480"
          allow="autoplay"
          title="collectbot"
        ></iframe>
      </div>

      <MainHomeComponent
        leftcomponent={<LeftComponent3 />}
        rightcomponent={<RightComponent3 width={width} />}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          padding: "0px 0px",
          backgroundColor: "#FFF6EF",
          margin: 0,
          paddingTop: 70,
          paddingBottom: 40,
        }}
        rightStyle={{
          padding: 0,
          margin: 0,
          height: "fit-content",
        }}
        leftStyle={{
          height: "fit-content",
        }}
      />

      <MainHomeComponent
        leftcomponent={<LeftComponent4 />}
        rightcomponent={<RightComponent4 width={width} />}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          padding: "100px 0px",
          backgroundColor: DARK_BLUE,
          margin: 0,
          backgroundImage: `url(${EllipseSvg})`,
          backgroundPosition: "right top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "22%",
          paddingTop: 70,
          paddingBottom: 40,
        }}
        rightStyle={{
          padding: 0,
          margin: 0,
          height: "fit-content",
        }}
        leftStyle={{
          height: "fit-content",
        }}
      />

      <CustomBrilliance />
    </div>
  );
}

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "400",
    color: LIGHT_FONT,
  },

  container: {
    backgroundColor: DARK_BLUE,
    width: "100%",
    height: "100%",
  },
  liStyles: {
    listStyleType: "disc",
    fontSize: 15,
    color: WHITE,
    fontWeight: "400",
    minWidth: 300,
    maxWidth: 450,
  },
  liStyle1: {
    margin: "20px 0px",
  
  },
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
  imageStyle: {
    objectFit: "contain",
    width: 150,
    height: 150,
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};
export default CollectbotIndex;

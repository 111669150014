import { NavLink, useLocation } from "react-router-dom";
import { DARK_BLUE, WHITE } from "../../utility/colorManager";
import CustomText from "./custom_text";

export const PrivateLink = ({
  to,
  title,
  style,
  onClick,
  id,
  textStyles,
  textColor = WHITE,
  className,
  width,
}) => {
  const location = useLocation();
  return (
    <>
      <div
        style={{
          padding: "10px 20px",
          ...style,
        }}
        // className="nav-active"
        onClick={onClick}
        key={id}
      >
        <NavLink
          to={to}
          className={(navData) => (navData.isActive ? "nav-active" : "")}
        >
          <CustomText
            style={{
              fontsize: 11,
              color:
                location.pathname === to
                  ? width > 900
                    ? DARK_BLUE
                    : "#FF8200"
                  : textColor,
              ...textStyles,
            }}
            className={className}
          >
            {title}
          </CustomText>
        </NavLink>
      </div>
    </>
  );
};

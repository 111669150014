import React from "react";
import { Link } from "react-router-dom";
import { arrowMarkUp } from "../../../utility/assetsManager";
import { BLACK } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomButton from "../custom_button";

const CustomBrilliance = () => {
  const { width } = ResizeListener()
  return (
    <div style={{...styles.bottomContainer, height:width>645? "75vh":"56vh",}}>
      <p
        style={{
          ...styles.text1,
          fontSize: width > 645 ? "26px" : "18px",
          fontWeight:700,
          marginBottom: width > 645 ? "0px" : "10px",          
        }}
      >
        Got an Idea?
      </p>
      <p style={{ ...styles.text2, fontSize: width > 645 ? 60 : "35px", }}>
        Let’s craft brilliance <br /> together!
      </p>
      <Link
        to={"/contact-us"} style={{ height: "0px" }}
      >
        <CustomButton
          title="Get in Touch"
          style={{
            margin: "30px 0px",
            borderRadius: "10px",
            width: width > 645 ? "140px" : "160px",
            height: width > 645 ? "40px" : "50px",
            paddingBottom: 3
          }}
          backgroundColor={"#1E2234"}
          icon={
            <img
              src={arrowMarkUp}
              style={{
                height: "10px",
                width: "10px",
                marginBottom: "4px",
                marginLeft: "-2px",
              }}
              alt="home-arrow"
            />
          }
        />
      </Link>
    </div>
  );
}

const styles = {
  ready: {
    fontSize: 32,
    background: "linear-gradient(90deg, #4947B8 , #FF9700)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    color: BLACK,
  },

  title1: {
    color: "#FF9800",
    fontSize: 50,
    fontWeight: "bold",
  },
  blackFonts: {
    fontWeight: "800",
    fontSize: "26px",
    marginLeft: "0px",
  },

  bottomContainer: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "80px 0px",
  },

  text1: {
    color: "white",
    fontWeight: 600
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: 800,

    // transform: "scaleX(1.1)",
  },
  imageStyle: {
    width: "150px",
    height: "30px",
    objectFit: "contain",
  },
};

export default CustomBrilliance

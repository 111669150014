import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Consent = ({ onClick }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    onClick(value);
  }, [value]);
  return (
    <div className="py-5">
      <div className="flex ">
        <input
          type="checkbox"
          style={{
            height: 20,
            width: 20,
            marginTop: 5,
          }}
          checked={value}
          className="checkbox"
          onChange={(val) => {
            if (val.target.name === value) {
              setValue("");
            } else setValue(val.target.name);
          }}
          name={"consent"}
        />
        <p
          className="text-[#000000] text-14 font-400 w-full ml-2"
          style={{
            color: "#CBCACA",
          }}
        >
          By signing up, I agree to Neokred's{" "}
          <Link
            style={{
              fontSize: "14px",
              fontFamily: "Satoshi",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            to="/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link
            to="/privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "14px",
              fontFamily: "Satoshi",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Privacy Policy
          </Link>
        </p>
      </div>

      {/* <div className="flex gap-4 justify-center items-center mt-3">
        <button
          className=" h-[40px] rounded-md bg-[#e0f2fe] mt-3 flex items-center justify-center p-2"
          onClick={onClick}
          disabled={value !== "consent"}
          style={{
            cursor: value !== "consent" ? "not-allowed" : "pointer",
          }}
        >
          Accept
        </button>
      </div> */}
    </div>
  );
};

export default Consent;

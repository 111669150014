import React from "react";
import { DailyfinanceSvg, DeccanSvg, EconomicSvg, Inc42Svg, MintSvg, RevopreneurSvg, StartupSvg, StatesmanSvg } from "../../../utility/assetsManager";
import { BLACK, WHITE } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";

const CustomFeaturedIn = () => {
  const { width } = ResizeListener()
  return (
    <div
      style={{
        backgroundColor: WHITE,
        padding: "60px 20px",
        textAlign: "center",
      }}
      className="flex flex-col justify-center items-center"
    >
      <p
        style={{
          ...styles.ready,
          fontSize: 30,
          fontWeight: "800",
        }}
      >
        Featured In
      </p>
      <div
        style={{
          height: width > 645 ? "auto" : "30px",
          display: width > 645 ? "grid" : "flex",
          overflowX: width > 645 ? "hidden" : "scroll",
          //marginLeft: width > 645 ? "0px" : "25px"
          // marginLeft: width > 645 ? "0px" : "25px"
        }}
        className="grid  mt-8 rid-cols-2 grid-rows-2  md:grid-cols-4 grid-rows-2 gap-14 "
        id="scrolleffect"
      >
        <img
          style={{ ...styles.imageStyle }}
          src={StartupSvg}
          alt="StartupSvg"
        />
        <img
          style={{ ...styles.imageStyle }}
          src={StatesmanSvg}
          alt="StatesmanSvg"
        />
        <img
          style={{ ...styles.imageStyle }}
          src={RevopreneurSvg}
          alt="RevopreneurSvg"
        />
        <img
          style={{ ...styles.imageStyle }}
          src={EconomicSvg}
          alt="EconomicSvg"
        />
        <img
          style={{ ...styles.imageStyle }}
          src={DailyfinanceSvg}
          alt="DailyfinanceSvg"
        />
       
        <img style={{ ...styles.imageStyle }} src={MintSvg} alt="MintSvg" />
        <img style={{ ...styles.imageStyle }} src={Inc42Svg} alt="Inc42Svg" />
        <img style={{ ...styles.imageStyle }} src={DeccanSvg} alt="DeccanSvg" />
      </div>

      <p
        style={{
          color: "#6C6B6B",
          fontSize: 18,
          marginTop: "40px",
          fontWeight: "700",
          display: width > 645 ? "flex" : "none",
        }}
      >
        and more...
      </p>
    </div>
  );
};

const styles = {
  ready: {
    fontSize: 32,
    background: "linear-gradient(90deg, #4947B8 , #FF9700)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    color: BLACK,
  },

  title1: {
    color: "#FF9800",
    fontSize: 50,
    fontWeight: "bold",
  },
  blackFonts: {
    fontWeight: "800",
    fontSize: "26px",
    marginLeft: "0px",
  },

  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },

  text1: {
    color: "white",
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",

    // transform: "scaleX(1.1)",
  },
  imageStyle: {
    width: "150px",
    height: "30px",
    objectFit: "contain",
  },
};

export default CustomFeaturedIn;

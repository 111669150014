import React from "react";
import {
  AddressSvg,
  CreditScoreSvg2,
  DocumentsSvg,
  EllipseSvg,
  JobProfileSvg,
  NameCardSvg,
  PhoneSvg1,
  PhoneSvg2,
  PhoneSvg3,
  PhoneSvg4,
  ProfilebgSvg,
} from "../../../utility/assetsManager";
import {
  BLACK,
  DARK_BLUE,
  LIGHT_FONT,
  WHITE,
} from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomBrilliance from "../../components/custom_brilliance";

const LeftComponent = ({ width }) => {
  return (
    <div
      style={{
        margin: "10px 0px",
        textAlign: "center",
        height: "75vh",
      }}
      className="flex flex-col items-center justify-center"
    >
      <p
        style={{
          ...styles.ready,
          marginBottom: width > 640 ? 0 : 100,
          paddingTop: width > 640 ? "0px" : "50px",
        }}
        className="text-3xl md:text-5xl"
      >
        ProfileX
      </p>

      <p
        style={{
          ...styles.para,
          maxWidth: width > 640 ? "" : "600px",
          paddingBottom: width > 640 ? "" : "50px",
        }}
        className="w-full text-center lg:w-3/4 text-2xl text-center"
      >
        customer details with just their mobile number. Onboard a customer
        within 10 seconds.
      </p>
    </div>
  );
};

const MainContainer = ({ width, height, icon, title, id }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        margin: "auto",
      }}
      key={id}
    >
      <img
        src={icon}
        alt={title}
        style={{
          minWidth: 250,
          maxWidth: 500,
          objectFit: "contain",
          height: 500,
          marginBottom: 20,
        }}
      />

      <p
        style={{
          color: BLACK,
          fontWeight: "600",
          margin: "10px 0px",
          maxWidth: "250px",
          textAlign: "center",
          minHeight: 50,
        }}
      >
        {title}
      </p>
    </div>
  );
};

const Container1 = ({ title, src }) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        width: "fit-content",
        padding: "10px 10px 10px 10px",
        margin: "10px 10px 10px 10px",
        borderRadius: 12,
        display: "flex",
        alignItems: "center",
        minWidth: 50,
        maxWidth: 700,
        objectFit: "contain",
        height: 60,
      }}
    >
      <img src={src} alt={title} />
      <p
        style={{
          color: "#FDFDFD",
          fontSize: 15,
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        {title}
      </p>
    </div>
  );
};
function ProfileX() {
  const { width, height } = ResizeListener();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: width > 640 ? "10px 20px" : "0px 10px",
          display: "flex",
          justifyContent: width > 640 ? "center" : "flex-end",
          alignItems: "center",
          boxSizing: "border-box",
          height: width > 640 ? height * 0.7 : "100%",
          backgroundColor: DARK_BLUE,
          backgroundImage: `url(${ProfilebgSvg}),url(${EllipseSvg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "50vh",
          backgroundSize: "150px,280px",
          // background:"linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
          // opacity: 0.1,
          // transform: "matrix(-1, 0, 0, 1, 0, 0)"
        }}
      >
        <LeftComponent width={width} />
      </div>

      <div>
        <iframe
          src="https://drive.google.com/file/d/1I3YtLtZUKfWcV2EWhUv4RT6Nx5YOftKl/preview"
          width="100%"
          height="480"
          allow="autoplay"
          title="profilex"
        ></iframe>
      </div>

      <div
        style={{
          padding: "15px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <p
          style={{
            ...styles.ready,
            fontSize: 29,
            lineHeight: 1,
            margin: "30px 30px 30px 50px",
          }}
          className="sm:text-left md:text-left lg:text-left"
        >
          Get users onboarded in 10 seconds!
        </p>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            width: "95%",
            justifyContent: "center",
            margin: "auto",
          }}
          className="justify-center lg:justify-between"
        >
          <MainContainer
            width={width}
            height={height}
            icon={PhoneSvg1}
            title="Enter mobile number"
            id={1}
          />
          <MainContainer
            width={width}
            height={height}
            icon={PhoneSvg2}
            title="ProfileX is fetching &
            verifying your profile"
            id={2}
          />
          <MainContainer
            width={width}
            height={height}
            icon={PhoneSvg3}
            title="Confirm profile"
            id={3}
          />
          <MainContainer
            width={width}
            height={height}
            icon={PhoneSvg4}
            title="User onboarded"
            id={4}
          />
        </div>
      </div>

      <div
        style={{
          backgroundColor: DARK_BLUE,
          width: "100%",
          textAlign: "center",
          padding: "55px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            ...styles.ready,
            fontSize: 26,
            textAlign: width > 600 ? "center" : "left",
          }}
        >
          ProfileX: Just enter the mobile number, we do the rest!
        </p>
        <p
          style={{
            ...styles.para,
            margin: "5px 0px",
            textAlign: width > 600 ? "center" : "left",
            fontSize: width > 600 ? 20 : 15,
          }}
        >
          With our robust APIs we are able fetch almost every single data 
          available of an individual.
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 15,
            margin: width > 600 ? "40px 0px 0px 40px" : "25x 0px 0px 0px",
            alignItems: width > 600 ? "center" : "left",
          }}
        >
          <Container1 title="Personal Details" src={NameCardSvg} />
          <Container1 title="Employment Details" src={JobProfileSvg} />
          <Container1 title="Credit Report" src={CreditScoreSvg2} />
          <Container1 title="Address Details" src={AddressSvg} />
          <Container1 title="Documents Images" src={DocumentsSvg} />
        </div>
      </div>

    <CustomBrilliance/>
    </div>
  );
}

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 20,
    color: LIGHT_FONT,
  },

  container: {
    backgroundColor: DARK_BLUE,
    width: "100%",
    height: "100%",
  },
  liStyles: {
    listStyleType: "disc",
    fontSize: 15,
    color: WHITE,
    fontWeight: "400",
    minWidth: 300,
    maxWidth: 450,
  },
  liStyle1: {
    margin: "20px 0px",
    
  },
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

export default ProfileX;

import React from "react";
import ResizeListener from "../../../utility/ResizeListener";
import {
  CodeSvg,
  CreditScoreSvg,
  EllipseSvg,
  ReceiptSearchSvg,
  ReceiptSvg,
  Roatate3dSvg,
  ShieldSvg,
  UserSearchSvg,
  VerificationSvg,
} from "../../../utility/assetsManager";
import {
  DARK_BLUE,
  LIGHT_FONT,
  WHITE,
} from "../../../utility/colorManager";
import CustomText from "../../components/custom_text";
import CustomBrilliance from "../../components/custom_brilliance";

export const Container = ({ icon, title }) => {
  const { width } = ResizeListener()
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
        maxWidth: 150,
        marginLeft: "-5%",
      }}
    >
      <img src={icon} height={60} width={40} alt={title} />
      <CustomText
        style={{
          fontSize: 13,
          color: "#1E2234",
          textAlign: "center",
          lineHeight: 1.1,
          marginTop: 17,
          width: width < 645 ? 100 : "",
          fontWeight: "500",
        }}
      >
        {title}
      </CustomText>
    </div>
  );
};

const ProductContainer = ({ title1, subTitle, li, title2, width }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFF6EF",
        // width: "fit-content",
        borderRadius: 10,
        minWidth: 250,
        maxWidth: 500,
        minHeight: width > 500 ? 300 : "",
        fontSize: "40px",
        position: "inherit",
        padding:'30px',
        margin:10
      }}
      // className="w-10/12 px-5 py-3 lg:w-7/4 m-1 lg:px-10 py-20 justify-center"
    >
      <p style={{ ...styles.ready, fontSize: width < 900 ? "30px" : "30px", }} className="text-1xl justify-center lg:text-1xl justify-center">
        {title1}
      </p>
      <p style={{ ...styles.ready, fontSize: width < 900 ? "30px" : "30px", }} className="text-1xl justify-center lg:text-1xl justify-center">
        {title2}
      </p>
      <p
        style={{
          color: "#6C6B6B",
          lineHeight: 1.3,
          margin: "20px 0px 0px",
          paddingRight: width < 900 ? "20px" : "100px",
          fontWeight: 600,
          fontSize:18
        }}
        className="text-sm lg:text-1xl"
      >
        {subTitle}
      </p>

      <ul style={{ ...styles.liStyles, color: "#1E2234", marginLeft: "10px", }} className="text-xl">
        {li}
      </ul>
    </div>
  );
};
const LeftComponent = ({ width }) => {
  return (
    <div
      style={{
        margin: "30px 10px 80px 10px",
        textAlign: "center",
        height: width > 900 ? "auto" : "75vh"

      }}
      className="flex flex-col items-center justify-center"
    >
      <p style={styles.ready} className="text-3xl md:text-5xl">
        Be a clever lender:
        <br /> Enhance your lending experience
      </p>

      <p
        style={{ ...styles.para, margin: "25px 10px 10px 20px" }}
        className={width > 645 ? "w-full text-center text-base lg:w-3/4  lg:text-lg" : ""}
      >
        A solution to provide you with a well-maintained and updated platform to
        accurately track all your lending data.
      </p>
    </div>
  );
};
function LendxIndex() {
  const { width, height } = ResizeListener();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: width > 640 ? "10px 20px" : "0px 10px",
          display: "flex",
          justifyContent: width > 640 ? "center" : "flex-end",
          alignItems: "center",
          boxSizing: "border-box",
          height: width > 640 ? height * 0.8 : "100%",
          backgroundColor: DARK_BLUE,
          backgroundImage: `url(${EllipseSvg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: width > 640 ? height * 0.6 : "60%",
        }}
      >
        <LeftComponent />
      </div>

      <div
        style={{

          margin: width < 900 ? "10% 7% 35% 8%" : "8% 5% 5% 8%",
        }}
      >
        <div>
          <p style={{ ...styles.ready, fontSize: 29, lineHeight: 1.3 }}>
            Our solutions
          </p>
          <p
            style={{
              ...styles.para,
              margin: "20px 39px 30px 0px ",
              color: "#6C6B6B",
              fontSize: 16,
              lineHeight: 1.3,
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            Automate and manage the end-to-end stages in the loan process.
          </p>
        </div>

        <div
          style={{
            maxWidth: "100%",
            margin: "30px 15px 40px 10px"
          }}
          className="grid justify-items-start grid-cols-2 grid-rows-2 my-10 md:grid-cols-4 grid-rows-2 gap-20"
        >
          <Container
            title="Easy Document 
            Verification"
            icon={VerificationSvg}
          />
          <Container
            title="Easy Integration 
            with APIs"
            icon={CodeSvg}
          />
          <Container
            title="End-to-End 
            Solution"
            icon={Roatate3dSvg}
          />
          <Container
            title="Loan Eligibility 
            Estimator"
            icon={CreditScoreSvg}
          />
          <Container title="Efficient Spends Analytics" icon={ReceiptSvg} />
          <Container
            title="Credit Assessment Tools"
            icon={ReceiptSearchSvg}
          />
          <Container
            title="360° Customer Profile Assessment"
            icon={UserSearchSvg}
          />
          <Container
            title="Superior Threat 
            Detection"
            icon={ShieldSvg}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: DARK_BLUE,
          padding: "30px 0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <p
            style={{
              ...styles.ready,
              fontSize: 29,
              lineHeight: 1,
              width: "fit-content",
              margin: "auto",
            }}
          >
            Products We Provide
          </p>
        </div>
      </div>


      <div
        style={{
          width: "100%",
          backgroundColor: DARK_BLUE,
          padding: "30px 0px",
          justifyItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          display: "flex",
          flexWrap: "wrap",
          rowGap: 15,
          columnGap: 15
        }}
      // className="grid  grid-cols-1 grid-rows-2 gap-10 place-content-center place-items-center	items-center md:grid-cols-2 grid-rows-1"
      //className="flex flex-wrap justify-center items-center"
      >
        <ProductContainer
          li={
            <>
              <li style={{fontSize:16}} className="text-base">Highly scalable</li>
              <li style={{fontSize:16}} className="text-base">No human errors</li>
              <li style={{fontSize:16}} className="text-base">
                Reduce default risk in lending
              </li>
              <li style={{fontSize:16}} className="text-base">
                Improves operational efficiencies
              </li>
              <li style={{fontSize:16}} className="text-base">
                Addresses common lending challenges{" "}
              </li>
            </>
          }
          title1="LOS - Loan"
          title2="Origination System"
          subTitle="Automate and manage the end-to-end stages in the loan process."
          width={width}
        />
        <ProductContainer
          li={
            <>
              <li style={{fontSize:16}} className="text-base">Saves money and time</li>
              <li style={{fontSize:16}} className="text-base">Check real-time revenue</li>
              <li style={{fontSize:16}} className="text-base">
                An end-to-end API based platform
              </li>
              <li style={{fontSize:16}} className="text-base">
                Smart mechanism for lending services
              </li>
              <li style={{fontSize:16}} className="text-base">
                One program to manage all the products{" "}
              </li>
            </>
          }
          title1="LMS -Loan"
          title2="Management System"
          subTitle="Automate and manage the end-to-end stages in the loan process."
          width={width}
        />

        <ProductContainer
          li={
            <>
              <li style={{fontSize:16}} className="text-base">Instant loan on KYC</li>
              <li style={{fontSize:16}} className="text-base">Paperless documentation process</li>
              <li style={{fontSize:16}} className="text-base">
               Low down-payment with attractive interest rates
              </li>
              <li style={{fontSize:16}} className="text-base">
                Tailored Two-Wheeler loan schemes to suit your needs
              </li>
              
            </>
          }
          title1="Two-Wheeler Loan:"
          title2="Easier, Faster & Safer"
          subTitle="Get instant loan sanctions with a completely digital process."
          width={width}
        />

        <ProductContainer
          li={
            <>
              <li style={{fontSize:16}} className="text-base">Fully Digital Onboarding</li>
              <li style={{fontSize:16}} className="text-base">
                Get Credit Limit based on your CIBIL score
              </li>
            </>
          }
          title1="Buy Now Pay Later"
          title2="Your Own Product"
          subTitle="Your system request and receives end user’s financial data insights in real-time. You will receive crunch clean standardised financials."
          width={width}
        />
      </div>
     <CustomBrilliance/>
    </div>
  );
}

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 126.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    lineHeight: 1.2,
    fontSize: "39px",
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    color: LIGHT_FONT,
  },

  container: {
    backgroundColor: DARK_BLUE,
    width: "100%",
    height: "100%",
  },
  liStyles: {
    listStyleType: "disc",
    fontSize: 15,
    color: WHITE,
    fontWeight: "400",
    maxWidth: 450,
  },
  liStyle1: {
    margin: "20px 0px",
  
  },
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
  imageStyle: {
    objectFit: "contain",
    width: 150,
    height: 150,
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

export default LendxIndex;

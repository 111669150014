import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  BekindSvg,
  Dipali,
  HandSvg,
  Harish,
  LinkedinSvg,
  NklogoSvg,
  Rohith,
  StorySvg,
  Tarun,
  ThinkteamSvg,
  Vivek,
  WorksmartSvg,
} from "../../../utility/assetsManager";
import {
  BLACK,
  DARK_BLUE,
  LIGHT_FONT,
  WHITE,
} from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomBrilliance from "../../components/custom_brilliance";
import CustomFeaturedIn from "../../components/custom_featuredin";
import "../home/home.css";

const LeftComponent = ({ width }) => {
  return (
    <div
      style={{
        margin: "10px 0px",
        textAlign: "center",
        height: width > 900 ? "auto" : "80vh",
      }}
      className="flex flex-col items-center justify-center"
    >
      <p style={styles.ready}>We build the future of banking</p>

      <p
        style={{
          ...styles.para,
          margin: width < 900 ? "50px" : "5px",
        }}
      >
        We don’t just work! We Invent 💛
      </p>
    </div>
  );
};
const AboutContainer = ({
  name,
  position,
  link,
  url,
  className,
  position1,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flex:"none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px 20px 10px",
        textAlign: "center",
        maxWidth: "220px",
      }}
      className={className}
    >
      <img src={url} alt="url" />
      <div
        style={{
          minHeight: 150,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 10,
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: 22,
          }}
        >
          {name}
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: 17,
            textAlign: "center",
          }}
        >
          {position}
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: 17,
            textAlign: "center",
          }}
        >
          {position1}
        </p>

        <img
          src={LinkedinSvg}
          style={{
            margin: "10px 0px",
            objectFit: "contain",
            width: 35,
            height: 35,
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(link, "_blank");
          }}
          alt="linkedin-about"
        />
      </div>
    </div>
  );
};

export const Container2 = ({
  title,
  subtitle,
  textColor,
  textstyle1,
  textstyle2,
  fillColor = BLACK,
}) => {
  return (
    <div
      style={{
        margin: "10px 35px",
      }}
      className="flex flex-col justify-center items-center mx-10"
    >
      <StorySvg
        style={{
          width: 35,
          height: 35,
          objectFit: "contain",
        }}
        fill={fillColor}
      />
      <p
        style={{
          fontWeight: "800",
          fontSize: 20,
          margin: "10px 0px 14px 0px",
          color: textColor ? textColor : "black",
          ...textstyle1,
        }}
      >
        {title}
      </p>
      <p
        style={{
          fontWeight: "600",
          fontSize: 14,
          padding: 0,
          margin: 0,
          width: 150,
          textAlign: "center",
          color: textColor ? textColor : "black",
          ...textstyle2,
        }}
      >
        {subtitle}
      </p>
    </div>
  );
};
export const Container3 = ({ title, src }) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        textAlign: "start",
        borderRadius: 7,
        minWidth: 200,
        maxHeight: 200,
      }}
      className="flex flex-col my-4 lg:my-0 justify-center text-center mx-10 px-4 py-10"
    >
      <p
        style={{
          fontWeight: "400",
          fontSize: 15,
          padding: 0,
          margin: 0,
          width: 150,
          color: WHITE,
          marginBottom: 30,
        }}
      >
        {title}
      </p>

      <img src={src} style={{ width: 100 }} alt="about-container-3" />
    </div>
  );
};
function AboutIndex() {
  const { width, height } = ResizeListener();
  const [size, setSize] = useState(false);
  useEffect(() => {
    if (width < 645) {
      setSize(true);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: width > 640 ? "10px 20px" : "0px 10px 0px",
          display: "flex",
          justifyContent: width > 640 ? "center" : "flex-end",
          alignItems: "center",
          boxSizing: "border-box",
          height: width > 900 ? height * 1 : "100%",
          backgroundColor: DARK_BLUE,
          backgroundImage: `url(${HandSvg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: width > 900 ? "50%" : "90%",
        }}
      >
        <LeftComponent width={width} />
      </div>

      <div
        style={{
          backgroundColor: "#FFF6EF",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "80px 0px",
        }}
      >
        <img
          src={NklogoSvg}
          style={{
            width: 60,
            height: 60,
            objectFit: "contain",
          }}
          alt="nk logo"
        />
        <p
          style={{
            ...styles.ready,
            fontSize: 25,
          }}
        >
          About Neokred
        </p>
        <p
          style={{
            minWidth: 300,
            maxWidth: 600,
            color: "#6C6B6B",
            margin: "10px 0px 0 2px",
          }}
        >
          Neokred is developing the technology that will enable the next
          generation of financial services. Our simple and robust
          Banking-as-a-Service APIs enables businesses to introduce new
          financial solutions in weeks rather than months.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "10px 0px 30px 0px",
        }}
      >
        <p>LEADERS</p>
        <p
          style={{
            ...styles.ready,
            textAlign: "center",
            padding: "20px",
          }}
          className="text-lg lg:text-4xl"
        >
          Vision Grit Perseverance
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              flexWrap: "wrap",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AboutContainer
              position="CEO | Co-Founder"
              name="Tarun Nazare"
              url={Tarun}
              link="https://in.linkedin.com/in/tarunnazare"
            />
            <AboutContainer
              position="Co-Founder"
              name="Rohith Reji"
              url={Rohith}
              link="https://in.linkedin.com/in/rohithreji"
            />
          </div>
          <div
            style={{
              flexWrap: "wrap",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <AboutContainer
              position="Director & COO"
              name="Hariharashudhan"
              url={Harish}
              link="https://in.linkedin.com/in/hariharashudhan"
            />
            <AboutContainer
              position="CBO"
              name="Vivek Sridhar"
              url={Vivek}
              link="https://in.linkedin.com/in/viveksridhar"
            />
            <AboutContainer
              name="Dipali Padia"
              position="President -"
              url={Dipali}
              link="https://in.linkedin.com/in/dipali-padia"
              position1="Banking Alliances & Partnerships"
            />
          </div>
        </div>
        <p
          style={{
            margin: "15px 0px",
            fontWeight: "bold",
            textDecoration: "underline",
            fontSize: 20,
            cursor: "pointer",
          }}
          onClick={() => {
            window.open("https://in.linkedin.com/company/neokred", "_blank");
          }}
        >
          Meet our tribe
        </p>
      </div>

      <div
        style={{
          backgroundColor: "#FFF6EF",
          padding: "100px 20px",
          textAlign: "center",
        }}
        className="flex flex-col justify-center items-center"
      >
        <p
          style={{
            ...styles.ready,
            fontSize: 30,
            fontWeight: 800,
          }}
        >
          Our Achievement
        </p>
        <p
          style={{
            minWidth: 300,
            maxWidth: 400,
            color: "#6C6B6B",
            margin: "30px 0px",
          }}
        >
          Our fintech solutions have not only won hearts and people, but also
          various awards.
        </p>
        <div className="flex flex-wrap items-center justify-center">
          <Container2
            title="REVOPRENEUR"
            subtitle="Top 10 Revolutionary Entrepreneurs 2022"
          />
          <Container2
            title="THE CEO STORY"
            subtitle="Company of the Year 2021"
          />
          <Container2
            title="ENTREPRENEUR"
            subtitle="Young Entrepreneur of the Year 2021"
          />
        </div>
      </div>

      <div
        style={{
          backgroundColor: DARK_BLUE,
          padding: "60px 20px",
          textAlign: "center",
        }}
        className="flex flex-col justify-center items-center"
      >
        <p
          style={{
            ...styles.ready,
            fontSize: 25,
          }}
        >
          core values
        </p>

        <div className="flex flex-wrap items-center justify-center mt-10">
          <Container3
            title="we go out  of our way to
            treat each other and our
            customers with care and 
            respect."
            src={BekindSvg}
          />
          <Container3
            title="we enjoy working together
            as a family."
            src={ThinkteamSvg}
          />
          <Container3
            title="we work together and
            push ourselves to
            win"
            src={WorksmartSvg}
          />
        </div>
      </div>

      <CustomFeaturedIn />
      <CustomBrilliance />
    </div>
  );
}

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "900",
    lineHeight: 1.2,
    fontSize: "34px",
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    color: LIGHT_FONT,
  },

  container: {
    backgroundColor: DARK_BLUE,
    width: "100%",
    height: "100%",
  },
  liStyles: {
    listStyleType: "disc",
    fontSize: 15,
    color: WHITE,
    fontWeight: "400",
    minWidth: 300,
    maxWidth: 450,
  },
  liStyle1: {
    margin: "20px 0px",

  },
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
  imageStyle: {
    width: "150px",
    height: "30px",
    objectFit: "contain",
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

export default AboutIndex;

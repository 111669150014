import React, { useRef, useState } from "react";
import { BLACK, DARK_BLUE, WHITE } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import "./home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router";
import {
  arrowMarkUp,
  Girl,
  HomeLogo,
  ManwithlaptopSvg,
} from "../../../utility/assetsManager";
import CustomButton from "../../components/custom_button";
import MainHomeContainer from "../../components/ui_components/MainHomeContainer";
import Expert from "./Expert";
import ProductScroll from "./ProductScroll";
import UspSlider from "./Slider";
import CustomFeaturedIn from "../../components/custom_featuredin";
import CustomBrilliance from "../../components/custom_brilliance";
import CustomAchievements from "../../components/custom_Achievements";
import Cookies from "../../../components/cookies";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Consent from "../../../components/consent";
const LeftComponent = ({ navigate }) => {
  let { width } = ResizeListener();
  return (
    <div
      style={{
        margin: width > 645 ? "132px 22px" : "60px 0px 37px 0px",
      }}
    >
      <p
        style={{
          ...styles.ready,
          fontSize: width > 645 ? "32px" : "28px",
          fontWeight: width > 645 ? 700 : 900,
        }}
      >
        Ready. Set.
      </p>
      <p
        style={{
          ...styles.title1,
          fontSize: width > 645 ? "50px" : "30px",
          fontWeight: 900,
        }}
      >
        Fintech.
      </p>

      <p
        style={{
          ...styles.para,
          margin: "5px 0px",
          fontSize: "18px",
        }}
        className={"text-base md:text-xl md:pr-28"}
      >
        We deliver a customer-focused experience with comprehensive financial
        solutions to fulfill all your financial requirements.
      </p>

      <CustomButton
        title="Get started with us!"
        icon={
          <img
            src={arrowMarkUp}
            style={{
              height: width > 645 ? "12px" : "9px",
              width: width > 645 ? "12px" : "9px",
              marginBottom: "2px",
              marginLeft: "-2px",
            }}
            alt="arrowMarkUphome"
          />
        }
        style={{
          marginTop: 20,
          padding: width > 645 ? "10px 20px" : "5px 15px",
        }}
        onClick={() => navigate("/contact-us")}
      />
    </div>
  );
};

const LeftComponent2 = ({ navigate }) => {
  let { width } = ResizeListener();
  return (
    <div>
      <p
        style={{ ...styles.ready, fontWeight: width > 645 ? 900 : 700 }}
        className="text-3xl"
      >
        Build and Launch
      </p>
      <p
        style={{ ...styles.ready, fontWeight: width > 645 ? 900 : 700 }}
        className="text-3xl"
      >
        with us in weeks
      </p>

      <p
        style={{
          ...styles.para,
          margin: width < 648 ? "" : "flex",
          color: WHITE,
          fontWeight: width > 645 ? "400" : "300",
          fontSize: width > 645 ? "18px" : "16px",
        }}
        className="md:pr-32 sm:text-base mt-4"
      >
        Neokred grants developers the tools they need to create simple and
        easy-to-use experiences for their consumers.
      </p>

      <CustomButton
        title="Learn More"
        icon={
          <img
            src={arrowMarkUp}
            style={{ height: "10px", width: "10px" }}
            alt="learnmore"
          />
        }
        style={{
          marginTop: 30,
          height: width > 645 ? "50px" : "50px",
          width: width > 645 ? "130px" : "170px",
        }}
        onClick={() => navigate("/contact-us")}
      />
    </div>
  );
};

const LeftComponent1 = ({ width, navigate }) => {
  return (
    <div
      style={{
        margin: "10px 0px 0px 0px",
        fontSize: 20,
      }}
    >
      <div
        className="slider-wrapper"
        style={{ minHeight: width > 645 ? "0px" : "20px" }}
      >
        <p
          style={{
            ...styles.blackFonts,
            fontSize: width > 645 ? "34px" : "24px",
            marginTop: width > 645 ? "3px" : "-10px",
          }}
        >
          Embedded
        </p>
        <div className="slider">
          <div
            className="slider-text1"
            style={{
              ...styles.blackFonts,
              fontSize: width > 645 ? "34px" : "24px",
            }}
          >
            Reward
          </div>
          <div
            className="slider-text2"
            style={{
              ...styles.blackFonts,
              fontSize: width > 645 ? "34px" : "24px",
            }}
          >
            Data
          </div>
          <div
            className="slider-text4"
            style={{
              ...styles.blackFonts,
              fontSize: width > 645 ? "34px" : "24px",
            }}
          >
            Credit
          </div>
          <div
            className="slider-text3"
            style={{
              ...styles.blackFonts,
              fontSize: width > 645 ? "34px" : "24px",
            }}
          >
            Banking
          </div>
        </div>
      </div>
      <p
        style={{
          ...styles.blackFonts,
          fontSize: width > 645 ? "34px" : "24px",
        }}
      >
        into your customer service
      </p>

      <p
        style={{
          ...styles.para,
          margin: width > 645 ? "30px 0px" : "25px -4px",
          fontWeight: width > 645 ? 500 : 400,
        }}
        className="md:pr-32"
      >
        Create unforgettable experiences for your clients or fans by leveraging
        integrated finance experiences.
      </p>

      <p
        style={{
          ...styles.para,
          margin: "10px 0px",
          width: width > 645 ? "auto" : "50%",
        }}
      >
        banking | wallets | cards | reward
      </p>

      <CustomButton
        title="Talk to us"
        onClick={() => navigate("/contact-us")}
        icon={
          <img
            src={arrowMarkUp}
            style={{
              height: "9px",
              width: "9px",
              marginBottom: "4px",
              marginLeft: "-2px",
            }}
            alt="talk to us"
          />
        }
        style={{
          borderRadius: "10px",
          marginTop: width > 640 ? "80px" : "40px",
          fontWeight: 700,
          width: width > 640 ? "140px" : "129px",
          height: width > 640 ? "50px" : "53px",
        }}
        backgroundColor="#1E2234"
      />
    </div>
  );
};

const RightComponent = ({ width }) => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <img
        src={HomeLogo}
        style={{
          minWidth: 320,
          objectFit: "contain",
          width: width * 0.4,
          maxWidth: 650,
          marginTop: width > 640 ? "0px" : "40px",
        }}
        alt="home-logo"
      />
    </div>
  );
};
const RightComponent1 = ({ width }) => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <img
        src={Girl}
        style={{
          minWidth: width > 645 ? 320 : 190,
          objectFit: "contain",
          width: width * 0.3,
          maxWidth: 500,
          maxHeight: 500,
          marginTop: width > 640 ? "34px" : "60px",
        }}
        alt="girl"
      />
    </div>
  );
};
const RightComponent2 = ({ width }) => {
  return (
    // <div
    //   style={{
    //     height: "auto",
    //     display: "flex",
    //     width: "100%",
    //     marginLeft: width > 645 ? "0px" : "20px",
    //     marginTop: width > 645 ? "0px" : "30px",
    //   }}
    //   className="px-4"
    // >
    <div
      className="code_div"
      style={{
        borderRadius: width > 645 ? "12px" : "8px",
        width: width > 645 ? "500px" : "320px",
        marginLeft: width > 645 ? 111 : 0,
        display: "flex",
        justifyContent: "center",
        height: width > 645 ? "250px" : "210px",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            width: width > 645 ? "400px" : "296px",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }} className="code_opentag">
              1
            </p>
            <p style={{ ...styles.imageText3 }}>form</p>
            <p style={{ color: "#9BAED2", height: "17px", paddingLeft: 2 }}>
              id=
            </p>
            <p style={{ ...styles.imageText1 }}>"link-forrm-id"</p>
            <p style={{ color: "#9BAED2", height: "17px" }}>method=</p>
            <p className="code_closetag" style={{ ...styles.imageText1 }}>
              "Get"
            </p>
            <p className="code_formtag"></p>
            <p className="code_closetag" style={{ ...styles.imageText3 }}>
              form
            </p>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }} className="code_opentag">
              2
            </p>
            <p style={{ ...styles.imageText3 }}>script</p>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }}>3</p>
            <p style={{ ...styles.imageText2 }}>src=</p>
            <p style={{ ...styles.imageText1 }}>
              "https://neokred.bankx.money:5000/login"
            </p>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }}>4</p>
            <p style={{ ...styles.imageText2 }}>client-name=</p>
            <p style={{ ...styles.imageText1 }}>"your brand"</p>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }}>5</p>
            <p style={{ ...styles.imageText2 }}>form-id=</p>
            <p style={{ ...styles.imageText1 }}>"link-form-id"</p>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }}>6</p>
            <p style={{ ...styles.imageText2 }}>product=</p>
            <p style={{ ...styles.imageText1 }}>"prepaid-card"</p>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }}>7</p>
            <p style={{ ...styles.imageText2 }}>env-name=</p>
            <p style={{ ...styles.imageText1 }} className="code_closetag">
              "finance-technology"
            </p>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              padding: width > 645 ? 4 : 2,
            }}
          >
            <p style={{ ...styles.imageText1 }} className="code_formtag">
              8
            </p>
            <p style={{ ...styles.imageText3 }} className="code_closetag">
              script
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Rightcomponent3 = ({ width }) => {
  return (
    <div
      className="w-full pl-10 md:pl-0 md:w-1/2"
      style={{
        position: "relative",
      }}
    >
      <UspSlider width={width} />
    </div>
  );
};

const LeftComponent3 = ({ width }) => {
  return (
    <div
      style={{
        width: width > 765 ? "45%" : "95%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: width > 765 ? "-45px" : "0px",
      }}
      className="sm:ml-0 "
    >
      <img
        src={ManwithlaptopSvg}
        style={{
          minWidth: width * 0.4,
          height: 360,
          minHeight: 300,
          marginTop: width > 645 ? 30 : 0,
        }}
        alt="manwithlaptop"
      />
    </div>
  );
};
const HomePageIndex = () => {
  const { width, height } = ResizeListener();

  const mainRef = useRef(null);
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: width,
      }}
      className="main"
      ref={mainRef}
    >
      <MainHomeContainer
        leftcomponent={<LeftComponent navigate={navigate} />}
        rightcomponent={<RightComponent height={height} width={width} />}
        height={height}
        width={width}
        reverse={true}
        style={{
          margin: 0,
          padding: 0,
        }}
        leftStyle={{ marginTop: "10px" }}
      />

      <MainHomeContainer
        leftcomponent={<LeftComponent1 width={width} navigate={navigate} />}
        rightcomponent={<RightComponent1 height={height} width={width} />}
        height={height}
        width={width}
        // reverse={true}
        style={{
          margin: 0,
          padding: 0,
          backgroundColor: "#FF9800",
          borderRadius: 0,
          height: "100%",
          paddingTop: "17px",
          marginTop: "10px",
          alignItems: "flex-start",
        }}
        leftStyle={{
          height: "100%",
        }}
        rightStyle={{
          padding: 0,
          margin: 0,
        }}
      />

      <ProductScroll />

      <MainHomeContainer
        leftcomponent={<LeftComponent2 navigate={navigate} />}
        rightcomponent={<RightComponent2 height={height} width={width} />}
        height={height}
        width={width}
        // reverse={true}
        style={{
          margin: 0,
          height: "fit-content",
          padding: "70px 0px",
          backgroundColor: DARK_BLUE,
          borderRadius: 0,
          minHeight: width * 0.2,
        }}
        rightStyle={{}}
        leftStyle={{}}
      />

      <div
        className="flex justify-between flex-col  py-5 md:flex-row px-0   "
        style={{
          backgroundColor: "#FFF6EF",
          padding: width > 645 ? "50px 0px" : "0px",
        }}
      >
        <LeftComponent3 height={height} width={width} />
        <Rightcomponent3 height={height} width={width} />
      </div>

      <div
        className="flex justify-between"
        style={{
          padding: "40px 0px",
          backgroundColor: "white",
        }}
      >
        <Expert />
      </div>

      <CustomAchievements />
      <CustomFeaturedIn />
      <CustomBrilliance />
    </div>
  );
};

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 , #FF9700)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",

    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    color: BLACK,
  },

  title1: {
    color: "#FF9800",
    fontSize: 50,
    fontWeight: "bold",
  },
  blackFonts: {
    fontWeight: "800",
    fontSize: "26px",
    marginLeft: "0px",
  },

  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },

  text1: {
    color: "white",
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",

    // transform: "scaleX(1.1)",
  },
  imageStyle: {
    width: "150px",
    height: "30px",
    objectFit: "contain",
  },
  imageText1: {
    color: "#DFDFDF",
    height: "17px",
  },
  imageText2: {
    color: "#9BAED2",
    height: "17px",
    marginLeft: 4,
  },
  imageText3: {
    color: "#FF9800",
    height: "17px",
  },
};

export default HomePageIndex;

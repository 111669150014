
  
  export const SliderData = [
    {
      title:
        "Everything is based on consumer behaviour. Consumers don't want finance; they want engaging and seamless experiences.",
      description1: `People want a fantastic automobile purchase experience 
      instead of auto loans (that just happens to have 
      a loan embedded)`,
      description:
        "Instead of waiting in line and paying, they want to enjoy their coffee (paying should happen in the background, unseen).",
    },
    {
      title: "Embedded finance is tactical.",
      description1:
        "Now is the time for companies to act innovatively, whether they are in the finance, sports, music etc. In fact, every business may modernise and utilise embedded finance.",
      description:
        "We think integrated money is an important strategic component of immersive consumer experiences that produces more daily engagement, more potential for income, and extremely insightful behavioural data.",
    },
    {
      title: "The change is happening and the change is now.",
      description1: "This is referred to as embedded finance experience.",
      description:
        "In order to boost your customer interactions, we designed the worldwide Neokred integrated finance experience platform with all the operations, financial products, tools, technologies, regulations, and knowledge you might possibly require. By seamlessly integrating finance into your digital journey and brand, it will enable you to quickly tap into the strategic value of frictionless finance experiences. Now, this is just the beginning. ",
    },
  ];
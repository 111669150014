import React from 'react'

const Terms = () => {
  return (
    <div style={{ margin: "auto", width: "50%" }}>
      <p style={{ fontSize: "30px", color: "#213554", marginTop: "10px" }}>Terms & Conditions </p>
      <p style={styles.ptag}>The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.</p>
      <p style={styles.ptag}>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern NEOKRED TECHNOLOGIES PRIVATE LIMITED''s relationship with you in relation to this website.</p>
      <p style={styles.ptag}>The term 'NEOKRED TECHNOLOGIES PRIVATE LIMITED' or 'us' or 'we' refers to the owner of the website whose registered/operational office is Neokred Office, 307, 9th Main Rd, 5th Block, Jayanagar Bengaluru KARNATAKA 560041. The term 'you' refers to the user or viewer of our website.</p>
      <p style={styles.ptag}>The use of this website is subject to the following terms of use:</p>
      <div style={{ marginLeft: "30px" }}>
        <ul>
          <li style={styles.list}>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
          <li style={styles.list}>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
          <li style={styles.list}>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
          <li style={styles.list}>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
          <li style={styles.list} >All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
          <li style={styles.list}>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
          <li style={styles.list}>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
          <li style={styles.list}>You may not create a link to this website from another website or document without NEOKRED TECHNOLOGIES PRIVATE LIMITED’s prior written consent.</li>
          <li style={styles.list}>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.</li>
        </ul>

        <p style={{ fontWeight: "bold", color: "#213554", textAlign: "center", marginTop: "10px", color: "#213554", }}>Neokred Technologies Private Limited</p>
        <p style={{ fontWeight: "bold", color: "#213554", textAlign: "center", color: "#213554", }}>DATA PROTECTION ADDENDUM</p>

        <p style={styles.ptag} >
          This Data Protection Addendum ("Addendum") between Neokred Technologies Private Limited (“Neokred Technologies Private Limited” or “Company”) and the Customer (as defined in the Agreement) forms part of the Neokred Technologies Private Limited Terms & Conditions set forth at https://www.neokred.tech or such other written or electronic agreement incorporating this Addendum, in each case governing Customer’s access to and use of the Services (the “Agreement”).
        </p>
        <p style={styles.ptag}>
          Customer enters into this Addendum on behalf of itself and any Affiliates authorized to use the Services under the Agreement and who have not entered into a separate contractual arrangement with Neokred Technologies Private Limited. For the purposes of this Addendum only, and except where otherwise indicated, references to "Customer" shall include Customer and such Affiliates.
        </p>

        <p style={styles.ptag}>
          The Parties hereby agree that the terms and conditions set out below shall be added as an Addendum to the Agreement.
        </p>

        <p style={{ fontWeight: 'bold', margin: "5px 0", color: "#213554", }}>1.Definitions</p>
        <p style={styles.ptag}>1.1 In this Addendum, the following terms shall have the meanings set out below and cognate terms shall be construed accordingly:</p>

        <ul style={styles.list}>
          <li>(a)"Affiliate" means an entity that owns or controls, is owned or controlled by or is or under common control or ownership with either Client or Company (as the context allows), where control is defined as the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of an entity, whether through ownership of voting securities, by contract or otherwise;</li>
          <li>(b)"Customer Personal Data" means any Personal Data provided by or made available by Customer to Neokred Technologies Private Limited or collected by Neokred Technologies Private Limited on behalf of Customer which is Processed by Neokred Technologies Private Limited to perform the Services;</li>
          <li>(c)"Controller to Processor Contractual Clauses" means the standard contractual clauses for cross-border transfers published by applicable Data Protection Laws or any similar such clauses adopted by a data protection regulator relating to Personal Data transfers to Third Countries, including without limitation any successor clauses thereto; </li>
          <li>(d)"Data Protection Laws" means any local, state, or national law regarding the processing of Personal Data applicable to Neokred Technologies Private Limited in the jurisdictions in which the Services are provided to Customer, including, without limitation, privacy, security, and data protection law;</li>
          <li>(e)"Services" means the services to be supplied by Neokred Technologies Private Limited to Customer or Customer’s Affiliates pursuant to the Agreement; and</li>
          <li>(f)“Third Country” means countries that, where required by applicable Data Protection Laws, have not received an adequacy decision from an applicable authority relating to cross-border data transfers of Personal Data, including regulators such as the European Commission, UK ICO, or Swiss FDPIC.</li>
        </ul>

        <p style={styles.ptag}>1.2	The terms “Business”, “Business Purpose”, “commercial purpose”, “Contractor”, "Controller", "Data Subject", "Personal Data", "Personal Data Breach", "Process", "Processor", “Sell”, “Service Provider”, “Share”, “Subprocessor”, "Supervisory Authority", and “Third Party” have the same meanings as described in applicable Data Protection Laws and cognate terms shall be construed accordingly.</p>
        <p style={styles.ptag}>1.3.	Capitalized terms not otherwise defined in this Addendum shall have the meanings ascribed to them in the Agreement.</p>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>2.	Roles of the Parties</p>


        <p style={styles.ptag}>2.1 The Parties acknowledge and agree that with regard to the Processing of Customer Personal Data, and as more fully described in Annex 1 hereto, Customer acts as a Business or Controller, and Neokred Technologies Private Limited acts as a Service Provider or Processor. This Addendum shall apply solely to the Processing of Customer Personal Data by Neokred Technologies Private Limited acting as a Processor, Subprocessor, or Third Party (as specified in Annex 1). </p>
        <p style={styles.ptag}>2.2.	The Parties expressly agree that Customer shall be solely responsible for ensuring timely communications to Customer's Affiliates or the relevant Controller(s) who receive the Services, insofar as such communications may be required or useful in light of applicable Data Protection Laws to enable Customer's Affiliates or the relevant Controller(s) to comply with such Laws. </p>



        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>3. Description and Purpose of Personal Data Processing</p>
        <p style={styles.ptag}>
          In Annex 1 to this Addendum, the Parties have mutually set out their understanding of the subject matter and details of the Processing of the Customer Personal Data to be Processed by Neokred Technologies Private Limited pursuant to this Addendum. The Parties may make reasonable amendments to Annex 1 on mutual written agreement and as reasonably necessary to meet those requirements. Annex 1 does not create any obligation or rights for any Party.
        </p>
        <p style={styles.ptag}>3.2.	The purpose of Processing under this Addendum is the provision of the Services pursuant to the Agreement and any Order Form(s).</p>


        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4. Description of Personal Data Processing</p>
        <p style={styles.ptag}>4.1.	Customer shall comply with all applicable Data Protection Laws in connection with the performance of this Addendum and the Processing of Customer Personal Data. In connection with its access to and use of the Services, Customer shall Process Customer Personal Data within such Services and provide Neokred Technologies Private Limited with instructions in accordance with applicable Data Protection Laws. As between the Parties, Customer shall be solely responsible for compliance with applicable Data Protection Laws regarding the collection of and transfer to Neokred Technologies Private Limited of Customer Personal Data. Customer agrees not to provide Neokred Technologies Private Limited with any data concerning a natural person's health, religion or any special categories of data as per applicable laws. </p>
        <p style={styles.ptag}>4.2.	Neokred Technologies Private Limited shall comply with all applicable Data Protection Laws in the Processing of Customer Personal Data and Neokred Technologies Private Limited shall:</p>

        <ul style={styles.list}>
          <li>(a)	Process the Customer Personal Data for the purposes of the Agreement and for the specific purposes in each case as set out in Annex 1 to this Addendum and otherwise solely on the documented instructions of Customer, for the purposes of providing the Services and as otherwise necessary to perform its obligations under the Agreement. The Agreement, this Addendum, and Customer’s use of the Services’ features and functionality are Customer’s written instructions to Neokred Technologies Private Limited in relation to Processing Customer Personal Data, including as follows: </li>
          <li>
            (i)	Neokred Technologies Private Limited shall use, retain, disclose, or otherwise Process Customer Personal Data only on behalf of Customer and for the specific business purpose of providing the Services and in accordance with Customer’s instructions, including as described in the Agreement. Neokred Technologies Private Limited shall not Sell or Share Customer Personal Data, nor use, retain, disclose, or otherwise Process Customer Personal Data outside of its business relationship with Customer or for any other purpose (including Neokred Technologies Private Limited’s commercial purpose) except as required or permitted by law. Neokred Technologies Private Limited shall immediately inform Customer (a) if Neokred Technologies Private Limited determines that it is no longer able to meet its obligations under Data Protection Laws or (b) if, in Neokred Technologies Private Limited's opinion, an instruction infringes applicable Data Protection Laws. Customer reserves the right to take reasonable and appropriate steps to ensure Graphite’s Processing of Customer Personal Data is consistent with Customer’s obligations under Data Protection Law and discontinue and remediate unauthorized use of Customer Personal Data;
          </li>
          <li>
            (ii)	Neokred Technologies Private Limited shall have rights to process Customer Personal Data solely (i) to the extent necessary to (a) perform the Business Purposes and its obligations under the Agreement; (b) operate, manage, test, maintain and enhance the Services including as part of its business operations; (c) to disclose aggregate statistics about the Services in a manner that prevents individual identification or re-identification of Customer Personal Data, including without limitation any individual device or individual person; and/or (d) protect the Services from a threat to the Services or Customer Personal Data; or (ii) if required by court order of a court or authorized governmental agency, provided that prior notice first be given to Customer; (iii) as otherwise expressly authorized by Customer;
          </li>
          <li>
            (iii)	Neokred Technologies Private Limited will not combine Customer Personal Data which Neokred Technologies Private Limited Processes on Customer’s behalf, with Personal Data which it receives from or on behalf of another person or persons, or collects from its own interaction with individual, provided that Graphite may combine personal information to perform any Business Purpose permitted or required under the Agreement to perform the Services;
          </li>
          <p style={styles.ptag}>
            (b)	implement and maintain measures designed to ensure that Neokred Technologies Private Limited personnel authorized to process the Customer Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality unless disclosure is required by law or professional regulations;
          </p>
          <p style={styles.ptag}>
            (c)	implement and maintain the technical and organizational measures set out in the Agreement, and, taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of Processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, implement and maintain any further commercially reasonable and appropriate administrative, technical, and organizational measures designed to ensure a level of security appropriate to the risk of the Processing of Customer Personal Data, and specifically:
          </p>

          <li>
            (i)	pseudonymization and encryption of Customer Personal Data;
          </li>
          <li>
            (ii)	ensuring ongoing confidentiality, integrity, availability and resilience of Neokred Technologies Private Limited’s processing systems and services that process Customer Personal Data;
          </li>
          <li>
            (iii)	restoring availability and access to Customer Personal Data in a timely manner in the event of a physical or technical incident; and
          </li>
          <li>
            (iv)	regularly testing, assessing and evaluating the effectiveness of technical and organizational measures for ensuring the security of the processing of the Customer Personal Data.
          </li>
          <p style={styles.ptag}>
            (d)	Customer hereby agrees that Neokred Technologies Private Limited is generally authorized to engage and appoint Sub-processors, and specifically the Sub-processors listed in Annex 2 hereto, subject to Neokred Technologies Private Limited's:
          </p>
          <li>
            (i)	notifying Customer at least thirty (30) calendar days in advance oof any intended changes or additions to its Sub-processors listed in Annex 2 by emailing notice of the intended change to Customer;
          </li>
          <li>
            (ii)	including data protection obligations in its contract with each Sub-processor that are materially the same as those set out in this Addendum; and
          </li>
          <li>
            (iii)	remaining liable to Customer for any failure by each Sub-processor to fulfill its obligations in relation to the Processing of the Customer Personal Data.
          </li>
          <li>
            In relation to any notice received under section 4.2(d)(i), Customer shall have a period of 30 (thirty) days from the date of the notice to inform Neokred Technologies Private Limited in writing of any reasonable objection on data protection grounds to the use of that Sub-processor. The parties will then, for a period of no more than 30 (thirty) days from the date of Customer's objection, work together in good faith to attempt to find a commercially reasonable solution for Customer which avoids the use of the objected-to Sub-processor. Where no such solution can be found, either Party may (notwithstanding anything to the contrary in the Agreement) terminate the relevant Services immediately on written notice to the other Party, without damages, penalty or indemnification whatsoever (but without prejudice to any fees incurred by Customer prior to termination);
          </li>

          <li>
            (e)	to the extent legally permissible, promptly notify Customer in case of any legally binding requests (i.e., disclosures required by law, court order, or subpoena) for disclosure of Customer Personal Data by Neokred Technologies Private Limited. In case if it is not legally binding then Customer Personal Data would not be disclosed and Neokred Technologies Private Limited will notify the Customer of such request rejection. A record of all legally binding disclosure requests relating to Customer Personal Data shall be maintained.
          </li>
          <li>
            (f)	to the extent legally permissible, promptly notify Customer of any communication from a Data Subject regarding the Processing of Customer Personal Data, or any other communication (including from a Supervisory Authority) relating to any obligation under the applicable Data Protection Laws in respect of the Customer Personal Data. Neokred Technologies Private Limited will not respond to any such request or complaint unless expressly authorized to do so by Customer or is otherwise required to respond under applicable Data Protection Laws. Taking into account the nature of the Processing, Neokred Technologies Private Limited will reasonably assist Customer (or the relevant Controller) by appropriate technical and organizational measures, insofar as this is possible, for the fulfillment of Customer's, Customer's Affiliates' or the relevant Controller(s)' obligation to respond to requests for exercising the data subject's rights. Customer agrees to pay Neokred Technologies Private Limited for time and for out-of-pocket expenses incurred by Neokred Technologies Private Limited in connection with the performance of its obligations under this Section 4.2(e);
          </li>
          <li>
            (g)	upon Neokred Technologies Private Limited's becoming aware of a Personal Data Breach involving Customer Personal Data, notify Customer without undue delay, of any Personal Data Breach involving Customer Personal Data, such notice to include, to the extent reasonably available to Neokred Technologies Private Limited, all timely information reasonably required by Customer (or the relevant Controller) to comply with its data breach reporting obligations under the applicable Data Protection Laws. Neokred Technologies Private Limited shall further take all such measures and actions as are necessary to remedy or mitigate the effects of such Security Incident and shall keep Customer reasonably informed of developments concerning Customer Personal Data;
          </li>
          <li>
            (h)	to the extent required by the applicable Data Protection Laws, provide reasonable assistance to Customer, Customer's Affiliates' or the relevant Controller(s)' with its obligations pursuant to applicable Data Protection Laws taking into account the nature of the Processing and information available to Neokred Technologies Private Limited; Customer agrees to pay Neokred Technologies Private Limited for time and for out of pocket expenses incurred by Neokred Technologies Private Limited in connection with any assistance provided in connection with applicable laws;
          </li>
          <li>
            (i)	cease Processing the Customer Personal Data upon the termination or expiry of the Agreement, and at option of Customer, Customer's Affiliates or the relevant Controller(s) either return or delete (including by ensuring such data is in non-readable format) all copies of the Customer Personal Data Processed by Neokred Technologies Private Limited, unless (and solely to the extent and for such period as) applicable law requires Neokred Technologies Private Limited to retain some or all  of the Customer Personal Data. Any such Customer Personal Data retained shall remain subject to the obligations of confidentiality set forth in the Agreement; and
          </li>
          <li>
            (j)	Neokred Technologies Private Limited shall maintain the necessary records in support of demonstrating compliance with its obligations (as specified in the applicable contract) for the processing of Customer Personal Data carried out on behalf of the Customer.
          </li>
          <li>
            (k)	make available to Customer all information reasonably necessary to demonstrate compliance with this Addendum and allow for and contribute to audits, including inspections, by Customer, or an independent third party auditor mandated by Customer, provided that Customer gives Neokred Technologies Private Limited reasonable prior notice of its intention to audit, conducts its audit during Neokred Technologies Private Limited’s normal business hours, and takes all reasonable measures to prevent unnecessary disruption to Neokred Technologies Private Limited’s operations. For the purposes of demonstrating compliance with this Addendum under this Section 4.2(i), the Parties agree that in the first instance, once per year during the term of the Agreement (except if and when required by instruction of a competent Supervisory Authority or where Customer believes a further audit is necessary due to a Personal Data Breach concerning Customer Personal Data suffered by Neokred Technologies Private Limited), Neokred Technologies Private Limited will provide to Customer responses to cybersecurity and other assessments and only where Customer cannot establish Neokred Technologies Private Limited’s compliance with this Addendum from Neokred Technologies Private Limited’s responses shall Customer request to inspect Neokred Technologies Private Limited’s processing operations. Customer agrees to pay Neokred Technologies Private Limited for time and for out of pocket expenses incurred by Neokred Technologies Private Limited in connection with assistance provided in connection with such audits, responses to cybersecurity and other assessments.
          </li>
        </ul>

        <p style={styles.ptag}>5.	Restricted Transfers</p>
        <p style={styles.ptag}>5.1.	The parties agree that when the transfer of Customer Personal Data from Customer and/or any of its Affiliates (as exporter) to Neokred Technologies Private Limited (as importer) is a Restricted Transfer and relevant Area Law applies, the transfer shall be subject to the appropriate Controller to Processor Contractual Clauses. </p>


        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>6.	Precedence</p>
        <p style={styles.ptag}>6.1.	The provisions of this Addendum are supplemental to the provisions of the Agreement. In the event of any inconsistency between the provisions of this Addendum and the provisions of the Agreement, the provisions of this Addendum shall prevail. In the event that any provision of this Addendum and/or the Agreement contradicts, directly or indirectly, the Controller to Processor Contractual Clauses, the Controller to Processor Contractual Clauses will control. </p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>7.	Indemnity</p>
        <p style={styles.ptag}>7.1.	To the extent permissible by law, Customer shall (a) defend Neokred Technologies Private Limited and its Affiliates (collectively, “Indemnified Parties”) from and against any and all claims, demands, suits, or proceedings made or brought against any of the Indemnified Parties by any third party (each, a “Claim”), and (b)  indemnify and hold harmless the Indemnified Parties from and against any and all  losses, damages, liabilities, fines and administrative fines, penalties, settlements,  and costs and expenses of any kind (including, without limitation, reasonable legal, investigatory and consultancy fees and expenses) incurred or suffered by any of the Indemnified Parties, in each case arising from any breach by Customer of this Addendum or of its obligations under applicable Data Protection Laws. Neokred Technologies Private Limited may participate in the defense and/or settlement of a Claim under this Section 7.1 with counsel of its choosing at its own expense.</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>8.	Severability</p>
        <p style={styles.ptag}>8.1.	The Parties agree that, if any section or sub-section of this Addendum is held by any court or competent authority to be unlawful or unenforceable, it shall not invalidate or render unenforceable any other section of this Addendum.</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>9.	Miscellaneous.</p>
        <p style={styles.ptag}>9.1.	The Addendum considers the following and follows:</p>
        <ul style={styles.list}>
          <li>
            (a)	Privacy by Design and default
          </li>

          <li>(b)	Achieving security of Processing</li>
          <li>(c)	Notification of breaches involving Customer Personal Data to the relevant Supervisory Authority</li>
          <li>(d)	Notification of breaches involving Customer Personal Data to Customer</li>
          <li>(e)	Conducting Privacy Impact Assessment where appropriate and required by applicable Data Protection Law</li>
          <li>(f)	Assurance of Neokred Technologies Private Limited’s assistance by if prior consultations with relevant Supervisory Authorities are needed and required by applicable Data Protection Laws.</li>

        </ul>
        <p style={styles.ptag}>
          9.2.	Neokred Technologies Private Limited shall comply with all statutory and regulatory requirements, ISO 27001:2013, ISO 27701:2019 and DPDPA requirements.
        </p>

        <p style={styles.ptag}>
          9.3.	In the event a Data Subject wishes to exercise its data subject rights under applicable Data Protection Law, including, but not limited to, a data subject’s right of access, correction and/or erasure of its Personal Data in Neokred Technologies Private Limited’s control, the Data Subjects can submit such request done by contacting Neokred Technologies Private Limited’s Data Protection Officer (DPO) below. Also for raising concerns and/or any complaints related to the Customer Personal Data that can be done by contacting the Data Protection Officer below:
        </p>

        <ul style={{ marginLeft: "10%", listStyle: "none" }}>
          <p style={{ fontWeight: "bold", color: "#213554" }}>
            Name: Tarun Suresh Nazare
          </p>
          <p style={{ fontWeight: "bold", color: "#213554" }}>
            Email ID: tarun@neokred.tech
          </p>

        </ul>

        <p style={styles.ptag}>9.4.	There are no Temporary files getting generated during processing.</p>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554", textAlign: "center" }}>Annex 1 to Data Protection Addendum</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554", textAlign: "center" }}>Description of Processing Activities for Customer Personal Data</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>1.	List of Parties </p>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>

          <i>Data Exporter </i>
        </p>

        <table style={styles.table}>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Name :</td>
            <td style={{ border: "1px solid black" }}>Customer (as defined in the Agreement)</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Address:</td>
            <td style={{ border: "1px solid black" }}> As set forth in the relevant Order Form. </td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Contact person’s name, position and contact details:</td>
            <td style={{ border: "1px solid black" }}> As set forth in the relevant Order Form. </td>

          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Activities relevant to the data transferred under these Clauses:</td>
            <td style={{ border: "1px solid black" }}>Recipient of the Services provided by Neokred Technologies Private Limited in accordance with the Agreement.</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Signature and date:  </td>
            <td style={{ border: "1px solid black" }}>Signature and date are set out in the Agreement.</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Role (controller/processor):</td>
            <td style={{ border: "1px solid black" }}>Controller</td>
          </tr>
        </table>

        <p style={styles.ptag}>
          <i style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>Data Importer </i>
        </p>

        <table style={styles.table}>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Name :</td>
            <td style={{ border: "1px solid black" }}>Neokred Technologies Private Limited</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Address:</td>
            <td style={{ border: "1px solid black" }}> #307/14, third floor, Diamond Arcade, 9th Main, 39th A Cross, 5th Block, JAYANAGAR, Bangalore - 560041 </td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Contact person’s name, position and contact details:</td>
            <td style={{ border: "1px solid black" }}> Tarun Suresh Nazare, Director, Co-Founder & CEO
              tarun@neokred.tech
            </td>

          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Activities relevant to the data transferred under these Clauses:</td>
            <td style={{ border: "1px solid black" }}>Provision of the Services to the Customer in accordance with the Agreement. </td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Signature and date:  </td>
            <td style={{ border: "1px solid black" }}>Signature and date are set out in the Agreement.</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Role (controller/processor):</td>
            <td style={{ border: "1px solid black" }}>Processor</td>
          </tr>
        </table>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>2.	Competent Supervisory Authority </p>
        <table style={styles.table}>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Identify the competent supervisory authority/ies in accordance with Applicable Data Protection Clause</td>
            <td style={{ border: "1px solid black" }}>Data Protection Authority </td>
          </tr>
        </table>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>3.	Processing Information</p>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>Subject matter and duration of the Processing of the Personal Data</p>
        <p style={styles.ptag}>
          The subject matter of the processing of personal data is to onboard the customer into our Customer applications, completing the KYC of customers and creating behavioral analytics of customers. The duration of the processing will extend for as long as necessary to achieve the specified purpose. Typically, this will include the entire duration of the customer or employee relationship, and data may be retained for a specific period following the termination of the relationship as per legal requirements and company policies.
        </p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>The nature and purpose of the Processing of the Personal Data</p>
        <p style={styles.ptag}>
          Nature: Neokred process personal data primarily for financial technology-related services and other products associated with us. Focusing on verifying the identity of the customer to prevent fraudulent activities and specialize in collecting and maintaining information about individuals and businesses.

        </p>
        <p style={styles.ptag}>
          Purpose: The purpose of processing personal data in fintech is to facilitate secure and efficient financial transactions, provide personalized financial services, and ensure compliance with regulatory requirements. Personal data is often used for identity verification, risk assessment, and improving user experiences.
        </p>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>The categories of Data Subject to whom the Customer Personal Data relates</p>
        <p style={styles.ptag}>
          The types of data include:
        </p>
        <p style={styles.ptag}>
          -	Personal information
        </p>
        <p style={styles.ptag}>
          -	Employment information
        </p>
        <p style={styles.ptag}>
          -	Credit background
        </p>
        <p style={styles.ptag}>
          -	Behavioral analysis
        </p>
        <p style={{ margin: "10px 0", fontWeight: "500",   color: "#515978",  fontSize: "20px" }}>
          The types of Customer Personal Data to be Processed
        </p>
        <p style={styles.ptag}>
          -	Personal identification information: This may include names, date of birth, gender, contact details (such as mobile numbers, email addresses), and residential addresses.
        </p>
        <p style={styles.ptag}>
          -	Financial information: This may involve bank account details and financial transaction records.
        </p>
        <p style={styles.ptag}>
          -	Employment information: Details related to employment history, occupation, and employer information.
        </p>
        <p style={styles.ptag}>
          -	Geolocation data: We may collect location data through mobile applications or other means.
        </p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>Special categories of data</p>
        <p style={styles.ptag}>None</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>The obligations and rights of Client</p>
        <p style={styles.ptag}>The obligations and rights of Client are set out in the Terms and this Addendum.</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>Data exporter (as applicable)</p>
        <p style={styles.ptag}>The data exporter is: Client of Company that uses the Services</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>Data importer (as applicable)</p>
        <p style={styles.ptag}>The data importer is: Neokred Technologies Private Limited, a company that provides services to the client, which requires receiving the Client’s query data</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>Processing operations (as applicable)</p>
        <p style={styles.ptag}>The personal data transferred will be subject to the following basic processing activities: The provision of Company Services to Client. In order to provide people data, Company receives identifying Personal Data to permit Company to query, cleanse, standardize, enrich, (when required) send to additional data to feed providers, and to store the query information.</p>
        {/* <p style={{margin:"10px 0",fontWeight:"bold"}}>Annex 2: Authorized Other Processors</p> */}


        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.	Technical and Organisational Security Measures</p>
        <p style={styles.ptag}>Description of the technical and organisational security measures implemented by Neokred Technologies Private Limited as the data processor/data importer to ensure an appropriate level of security, taking into account the nature, scope, context, and purpose of the processing, and the risks for the rights and freedoms of natural persons. </p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.1.	Security</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.1.1.	Security Management System.</p>
        <ul style={styles.list}>
          <li>(a)	Organization. Neokred Technologies Private Limited designates qualified security personnel whose responsibilities include development, implementation, and ongoing maintenance of the Information Security Program.</li>
          <li>(b)	Policies. Management reviews and supports all security related policies to ensure the security, availability, integrity and confidentiality of Customer Personal Data.  These policies are updated at least once annually.</li>
          <li>(c)	Assessments. Neokred Technologies Private Limited engages a reputable independent third-party to perform risk assessments of all systems containing Customer Personal Data at least once annually.</li>
          <li>(d)	Risk Treatment. Neokred Technologies Private Limited maintains a formal and effective risk treatment program that includes penetration testing, vulnerability management and patch management to identify and protect against potential threats to the security, integrity or confidentiality of Customer Personal Data.</li>
          <li>(e)	Vendor Management. Neokred Technologies Private Limited maintains an effective vendor management program</li>
          <li>(f)	Incident Management. Neokred Technologies Private Limited reviews security incidents regularly, including effective determination of root cause and corrective action.</li>
          <li>(g)	Standards. Neokred Technologies Private Limited operates an information security management system that complies with the requirements of ISO/IEC 27001:2013 standard.</li>
        </ul>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.2.	Personnel Security.</p>
        <p style={styles.ptag}>4.2.1.	Neokred Technologies Private Limited personnel are required to conduct themselves in a manner consistent with the company’s guidelines regarding confidentiality, business ethics, appropriate usage, and professional standards. Neokred Technologies Private Limited conducts reasonably appropriate background checks on any employees who will have access to Customer data under this Agreement, including in relation to employment history and criminal records, to the extent legally permissible and in accordance with applicable local labor law, customary practice and statutory regulations.</p>
        <p style={styles.ptag}>4.2.2.	Personnel are required to execute a confidentiality agreement in writing at the time of hire and to protect Customer Personal Data at all times. Personnel must acknowledge receipt of, and compliance with, Neokred Technologies Private Limited’s confidentiality, privacy and security policies. Personnel are provided with privacy and security training on how to implement and comply with the Information Security Program. Personnel handling Customer Personal Data are required to complete additional requirements appropriate to their role (e.g., certifications). Neokred Technologies Private Limited’s personnel will not process Customer Personal Data without authorization.</p>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.3.	Access Controls</p>
        <p style={styles.ptag}>4.3.1.	Access Management. Neokred Technologies Private Limited maintains a formal access management process for the request, review, approval and provisioning of all personnel with access to Customer Personal Data to limit access to Customer Personal Data and systems storing, accessing or transmitting Customer Personal Data to properly authorized persons having a need for such access. Access reviews are conducted periodically to ensure that only those personnel with access to Customer Personal Data still require it.</p>

        <p style={styles.ptag}>

          4.3.2.	Infrastructure Security Personnel. Neokred Technologies Private Limited has, and maintains, a security policy for its personnel, and requires security training as part of the training package for its personnel. Neokred Technologies Private Limited’s infrastructure security personnel are responsible for the ongoing monitoring of Neokred Technologies Private Limited’s security infrastructure, the review of the Services, and for responding to security incidents.
        </p>
        <p style={styles.ptag}>
          4.3.3.	Access Control and Privilege Management. Neokred Technologies Private Limited’s and Customer’s administrators and end users must authenticate themselves via a Multi-Factor authentication system or via a single sign on system in order to use the Services
        </p>
        <p style={styles.ptag}>
          4.3.4.	Internal Data Access Processes and Policies – Access Policy. Neokred Technologies Private Limited’s internal data access processes and policies are designed to protect against unauthorized access, use, disclosure, alteration or destruction of Customer Personal Data. Neokred Technologies Private Limited designs its systems to only allow authorized persons to access data they are authorized to access based on principles of “least privileged” and “need to know”, and to prevent others who should not have access from obtaining access.  Neokred Technologies Private Limited requires the use of unique user IDs, strong passwords, two factor authentication and carefully monitored access lists to minimize the potential for unauthorized account use. The granting or modification of access rights is based on: the authorized personnel’s job responsibilities; job duty requirements necessary to perform authorized tasks; a need to know basis; and must be in accordance with Neokred Technologies Private Limited’s internal data access policies and training. Approvals are managed by workflow tools that maintain audit records of all changes. Access to systems is logged to create an audit trail for accountability. Where passwords are employed for authentication (e.g., login to workstations), password policies follow industry standard practices. These standards include password complexity, password expiry, password lockout, restrictions on password reuse and re-prompt for password after a period of inactivity.
        </p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.4.	Data Center and Network Security</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.4.1.	Data Centers.</p>
        <ul style={styles.list}>
          <li>
            (a)	Infrastructure. Neokred Technologies Private Limited has AWS as its data center.
          </li>
          <li>(b)	Resiliency. Multi Availability Zones are enabled on AWS and Neokred Technologies Private Limited conducts Backup Restoration Testing on regular basis to ensure resiliency.</li>
          <li>(c)	Server Operating Systems. Neokred Technologies Private Limited’s servers are customized for the application environment and the servers have been hardened for the security of the Services. Neokred Technologies Private Limited employs a code review process to increase the security of the code used to provide the Services and enhance the security products in production environments.</li>
          <li>
            (d)	Disaster Recovery. Neokred Technologies Private Limited replicates data over multiple systems to help to protect against accidental destruction or loss. Neokred Technologies Private Limited has designed and regularly plans and tests its disaster recovery programs.
          </li>
          <li>
            (e)	Security Logs. Neokred Technologies Private Limited’s systems have logging enabled to their respective system log facility in order to support the security audits, and monitor and detect actual and attempted attacks on, or intrusions into, Neokred Technologies Private Limited’s systems.
          </li>
          <li>
            (f)	Vulnerability Management. Neokred Technologies Private Limited performs regular vulnerability scans on all infrastructure components of its production and development environment.  Vulnerabilities are remediated on a risk basis, with Critical, High and Medium security patches for all components installed as soon as commercially possible.
          </li>
        </ul>

        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554" }}>4.4.2.	Networks and Transmission.</p>

        <ul style={styles.list}>
          <li>(a)	Data Transmission. Transmissions on production environment are transmitted via Internet standard protocols.</li>
          <li>(b)	External Attack Surface. AWS Security Group which is equivalent to virtual firewall is in place for Production environment on AWS.</li>
          <li>(c)	Incident Response. Neokred Technologies Private Limited maintains incident management policies and procedures, including detailed security incident escalation procedures. Neokred Technologies Private Limited monitors a variety of communication channels for security incidents, and Neokred Technologies Private Limited’s security personnel will react promptly to suspected or known incidents, mitigate harmful effects of such security incidents, and document such security incidents and their outcomes.</li>
          <li>(d)	Encryption Technologies. Neokred Technologies Private Limited makes HTTPS encryption (also referred to as SSL or TLS) available for data in transit.</li>

        </ul>

        <p style={{ fontWeight: "bold", color: "#213554", margin: "10px 0" }}>
          4.5.	Data Storage, Isolation, Authentication, and Destruction.
        </p>
        <p style={styles.ptag}>
          Neokred Technologies Private Limited stores data in a multi-tenant environment on AWS servers. Data, the Services database and file system architecture are replicated between multiple availability zones on AWS. Neokred Technologies Private Limited logically isolates the data of different customers. A central authentication system is used across all Services to increase uniform security of data. Neokred Technologies Private Limited ensures secure disposal of Customer Data through the use of a series of data destruction processes.
        </p>






        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554", textAlign: "center" }}>Annex 2</p>
        <p style={{ margin: "10px 0", fontWeight: "bold", color: "#213554", textAlign: "center" }}>Neokred Technologies Private Limited’s Sub-processors</p>

        <table style={styles.table}>
          <tr style={{ border: "1px solid black" }}>
            <th style={{ border: "1px solid black" }}>Name of Other Processor</th>
            <th style={{ border: "1px solid black" }}>Description of Processingact</th>
            <th style={{ border: "1px solid black" }}>Location of other Processor</th>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Amazon Web Services</td>
            <td style={{ border: "1px solid black" }}>Running the Production environment including the Application and Databases</td>
            <td style={{ border: "1px solid black" }}>India</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Microsoft</td>
            <td style={{ border: "1px solid black" }}>Email services</td>
            <td style={{ border: "1px solid black" }}>India</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Zoho</td>
            <td style={{ border: "1px solid black" }}>Invoicing solution</td>
            <td style={{ border: "1px solid black" }}>India</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Tally </td>
            <td style={{ border: "1px solid black" }}>Invoicing & ERP solution</td>
            <td style={{ border: "1px solid black" }}>India</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Razorpay</td>
            <td style={{ border: "1px solid black" }}>Payment Gateway</td>
            <td style={{ border: "1px solid black" }}>India</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Github</td>
            <td style={{ border: "1px solid black" }}>Code version control</td>
            <td style={{ border: "1px solid black" }}>US</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}>Slack</td>
            <td style={{ border: "1px solid black" }}>Messaging </td>
            <td style={{ border: "1px solid black" }}>US</td>
          </tr>
        </table>

      </div>
      <p style={{ fontSize: "18px", padding: "10px 0 10px", color: "#515978" }}>We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time</p>
    </div>
  )

}

const styles = {
  list: {
    fontSize: "18px",
    color: "#515978",
  },
  ptag: {
    fontSize: "18px",
    padding: "10px 0 10px",
    color: "#515978"
  },
  table: {
    color: "#213554",
  }
}






export default Terms

export const NavBarOptionsWeb = [
  // {
  //   name: "Prepaid Cards",
  //   to: "/cards",
  //   isLink: true,
  // },
  {
    name: "Smart Payments",
    to: "/wearables",
    isLink: true,
  },
  // {
  //   name: "LendX",
  //   to: "/lendx",
  //   isLink: true,
  // },
  {
    name: "ProfileX",
    to: "/profilex",
    isLink: true,
  },

  {
    name: "Collectbot",
    to: "/collectbot",
    isLink: true,
  },
  // {
  //   name: "Datai",
  //   to: "/data-ai",
  //   isLink: true,
  // },
  // {
  //   name: "GiftOn",
  //   to: "/gifton",
  //   isLink: true,
  // },
  {
    name: "KYC",
    to: "/kyc",
    isLink: true,
  },
  {
    name: "Polici",
    to: "/polici",
    isLink: true,
  },
];

export const NavBarOptionsMobile = [
  {
    name: "Prepaid Cards",
    to: "/cards",
    isLink: true,
  },
  {
    name: "Smart Payments",
    to: "/wearables",
    isLink: true,
  },
  // {
  //   name: "LendX",
  //   to: "/lendx",
  //   isLink: true,
  // },
  {
    name: "ProfileX",
    to: "/profilex",
    isLink: true,
  },

  {
    name: "Collectbot",
    to: "/collectbot",
    isLink: true,
  },
  // {
  //   name: "Datai",
  //   to: "/data-ai",
  //   isLink: true,
  // },
  {
    name: "GiftOn",
    to: "/gifton",
    isLink: true,
  },
  {
    name: "KYC",
    to: "/kyc",
    isLink: true,
  },
  {
    name: "Polici",
    to: "/polici",
    isLink: true,
  },
  {
    name: "About",
    to: "/aboutus",
    isLink: true,
  },
  {
    name: "Contact us",
    to: "/contact-us",
    isLink: true,
  },
  {
    name: "Login",
    to: "/login",
    isLink: true,
  },
];

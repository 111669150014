import React from "react";
import {
  AppStoreWhite,
  EllipseSvg,
  GooglePlaySvg,
  Keybg,
  Mobilekey,
  NeokeySvg,
  NeoloopSvg,
  NeostrapSvg,
} from "../../../utility/assetsManager";
import { BLACK, DARK_BLUE, LIGHT_FONT } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import MainHomeComponent from "../../components/Main_component";
import SVG from "react-inlinesvg";
import CustomBrilliance from "../../components/custom_brilliance";

const LeftComponent = () => {
  const { width } = ResizeListener;
  return (
    <div
      style={{
        margin: "50px 10px 80px",
      }}
    >
      <p style={{ ...styles.ready, fontWeight: 1000 }} className="text-3xl	md:text-5xl sm:justify-items-center lg:justify-start">
        Trendy. Cool. Smart.
      </p>

      <p
        style={{
          ...styles.para,
          margin: "30px 0px 0px 5px",
          width: width < 900 ? "500px" : "300px",
        }}
      >
        Payment, technology, fashion and sports all connect in wearable devices.
      </p>

      <div
        style={{
          marginTop: 40,
        }}
        className="hidden lg:flex flex-col"
      >
        <div>
          <p style={styles.para}>Download Neokey</p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <a href="https://play.google.com/store/apps/details?id=com.neokredneokey">
          <SVG
            src={GooglePlaySvg}
            style={{
              objectFit: "contain",
              margin: "10px 0px 23px 0px",
              paddingTop: "5px",
              cursor: "pointer"
            }}
            fill="white"
          />
          </a>
          <a href="https://apps.apple.com/in/app/neokey/id1592261859">
          <img
            style={{
              maxWidth: 190,
              objectFit: "contain",
              margin: "10px 0px 20px 20px",
              width: 150,
              height: 50,
              paddingTop: "1px",
              cursor:"pointer"
            }}
            src={AppStoreWhite}
            alt="wearables-appstore"
          />
          </a>
        </div>
      </div>
    </div>
  );
};

const RightComponent = ({ width }) => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "1500%",
        marginTop: 50,
        backgroundImage: `url(${EllipseSvg})`,
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "50%",
        padding: 0,
      }}
    >
      <img
        src={Mobilekey}
        style={{
          minWidth: 280,
          maxWidth: 550,
          objectFit: "contain",
          width: width * 0.35,
          marginBottom: -27,
        }}
        alt="mobilekey"
      />
    </div>
  );
};

const LeftComponent2 = () => {
  return (
    <div style={{}} className="px-6 py-300 lg:px-2">
      <p
        style={{ ...styles.ready, lineheight: "1.5", marginBottom: "24px" }}
        className="text-3xl	lg:text-2xl mt-0"
      >
        Never lose your swag.
        <br />A coolest and fastest wallet for your swagittude.
      </p>

      <p
        style={{
          ...styles.para,
          margin: "7px 0px 0px",
          color: "#6C6B6B",
          lineHeight: "1.5",
        }}
      >
        No more bulky wallets or multiple cards, just spin your new wallet
        around your finger.
      </p>

      <div
        style={{
          marginTop: 30,
        }}
      >
        <ul style={{ ...styles.liStyles, color: BLACK, marginLeft: "10px" }}>
          <li>Tap to Pay</li>
          <li>Safe and Secure</li>
          <li>Amount Reloadable</li>
          <li>Stylish Look</li>
        </ul>
      </div>
    </div>
  );
};

const MainContainer = ({ width, height, icon, title, id }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        margin: "20px 10px",
      }}
      key={id}
    >
      <img
        src={icon}
        alt={title}
        style={{
           minWidth: 150,
          maxWidth: 300,
          objectFit: "contain",
        }}
      />

      <p
        style={{
          color: BLACK,
          fontWeight: "600",
          margin: "15px 0px",
        }}
      >
        {title}
      </p>
    </div>
  );
};

const RightComponent2 = () => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <img
        src={Keybg}
        style={{
          objectFit: "contain",
          minWidth: 100,
          maxWidth: 300,
          marginTop: "30px",
        }}
        alt="Keybg"
      />
    </div>
  );
};

function Wearables() {
  const { width } = ResizeListener();
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <MainHomeComponent
        leftcomponent={<LeftComponent />}
        rightcomponent={<RightComponent width={width} />}
        style={{
          backgroundColor: DARK_BLUE,
          width: "100%",
          borderRadius: 0,
          height: width < 900 ? "75vh" : "60vh",
          paddingTop: 30,
          paddingBottom: 0,
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <iframe
          src="https://drive.google.com/file/d/1_NNtEW3DLDDHyHeqCkVIOqwkp7MPqDH9/preview"
          width="100%"
          height="480"
          allow="autoplay"
          title="wearable"
        ></iframe>
      </div>

      <MainHomeComponent
        leftcomponent={<LeftComponent2 />}
        rightcomponent={<RightComponent2 />}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          padding: "0px 0px",
          backgroundColor: "#FFFFFF",
          margin: 0,
          paddingTop: 60,
        }}
        rightStyle={{
          padding: 0,
          margin: 0,
          height: "fit-content",
        }}
        leftStyle={{
          height: "fit-content",
        }}
      />

      <div
        style={{
          backgroundColor: "#FFF6EF",
        }}
        className="flex flex-col justify-center items-center text-center pt-16 lg:pt-10"
      >
        <p
          style={{
            ...styles.ready,
            fontSize: 30,
            fontWeight: width < 900 ? 610 : 900,
            padding: "40px 1px",
            width: width < 900 ? 400 : 1000,
          }}
        >
          <p style={{ ...styles.ready, fontSize: 30, fontWeight: width < 900 ? 610 : 900, padding: "40px 1px", width: width < 900 ? '90%' : 1000 }}>
            Next-gen contactless smart payment devices
          </p>
        </p>
        <div className="flex flex-wrap justify-center items-end  gap-14">
           <MainContainer title="Neokey" icon={NeokeySvg} />
          <MainContainer title="Neoloop" icon={NeoloopSvg} />
          <MainContainer title="Neostrap" icon={NeostrapSvg} />
        </div>
      </div>
      <CustomBrilliance />
    </div>
  );
}

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "bold",
    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    color: LIGHT_FONT,
  },

  container: {
    backgroundColor: DARK_BLUE,
    width: "100%",
    height: "100%",
  },
  liStyles: {
    listStyleType: "disc",
    fontSize: 19,
    color: BLACK,
    fontWeight: "400",
    marginTop: 20,
    marginBottom: 30,
    minWidth: 300,
    maxWidth: 450,
  },
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

export default Wearables;

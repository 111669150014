import React from "react";
import CustomText from "../../components/custom_text";
import "../../../App.css";
import ResizeListener from "../../../utility/ResizeListener";

const ButtonComponent = ({ title, href = "/" }) => {
  return (
    <a
      href={href}
      target="_blank"
      style={{
        border: "1px solid #DFDFDF",
        borderRadius: "10px",
        width: "280px",
        height: "45px",
        marginBottom: "20px",
        color: "white",
      }}
      className="flex justify-center items-center"
      rel="noreferrer"
      id="login_button"
    >
      {title}
    </a>
  );
};

const LoginIndex = () => {
  let { width } = ResizeListener();
  return (
    <div
      style={{ backgroundColor: "#1E2234", height: "95vh" }}
      className="w-full flex flex-col justify-center items-center"
    >
      <CustomText
        style={{
          background: "linear-gradient(to right, #4947B8 , #FF9700)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontWeight: "900",
          fontFamily: "Satoshi",
          fontSize: "40px",
          marginBottom: "25px",
        }}
      >
        Login to the future
      </CustomText>
      <div>
        <div className={width > 645 ? `login` : "null"}>
          <ButtonComponent
            href={"https://corporate.baink.club/login"}
            title={"GiftOn - Corporate Portal"}
          />
        </div>
        <div className={width > 645 ? `login` : "null"}>
          <ButtonComponent
            href={"https://neokred.bankx.money/login"}
            title={"Prepaid Card - Corporate Portal"}
          />
        </div>
        <div className={width > 645 ? `login` : "null"}>
          <ButtonComponent
            href={"https://collectbot.neokred.tech/login"}
            title={"Collect Bot - Corporate Portal"}
          />
        </div>
        <div className={width > 645 ? `login` : "null"}>
          <ButtonComponent
            href={"https://auth.neokred.tech/login"}
            title={"Profilex"}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginIndex;

import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation, useNavigate } from "react-router";
import { NavBarOptionsWeb } from "../data";
import CustomText from "../presentation/components/custom_text";
import { PrivateLink } from "../presentation/components/PrivateLink";
import { NeokredLogo, NeokredWhiteSvg } from "../utility/assetsManager";
import ResizeListener from "../utility/ResizeListener";
import useScreenOrientation from "./../utility/screenOrientation";

function TopNavbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const { orientaion } = useScreenOrientation();
  const { width } = ResizeListener();

  const location = useLocation();
  const ref = useRef();
  const ref1 = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenNavbar(false);
      }
    }

    // window.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleClickOutside);
    return () => {
      // window.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    console.log(orientaion);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        boxSizing: "border-box",
        top: 0,
        zIndex: 1000,
        minWidth: location.pathname === "/contactus" ? "100vw" : "auto",
        transition: "all 0.5s",
        backgroundColor: location.pathname === "/home" ? "white" : "#1E2234",
        position: "relative",
      }}
    >
      <div
        style={{
          maxWidth: 1440,
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "0.5rem 3rem",
        }}
      >
        <div
          style={{
            width: "20%",
            minWidth: 120,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            navigate("/home");
          }}
        >
          <img
            src={location.pathname === "/home" ? NeokredLogo : NeokredWhiteSvg}
            style={{
              width: 140,
              cursor: "pointer",
            }}
            alt="neokred-logo"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "80%",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "5rem",
            }}
          >
            <div
              className="flex items-center"
              // onMouseEnter={() => {
              //   // setOpenNavbar(true);
              // }}
              // omMouseLeave={() => {
              //   setOpenNavbar(false);
              // }}
              onClick={() => {
                setOpenNavbar(!openNavbar);
              }}
              ref={ref}
            >
              <CustomText
                style={{
                  fontsize: 10,
                  color: location.pathname !== "/home" ? "white" : "#1E2234",
                  cursor: "pointer",
                  fontWeight: 550,
                  letterSpacing: "0.15px",
                }}
                className={"open-sans"}
              >
                Products
              </CustomText>

              {openNavbar === true ? (
                <IoIosArrowUp
                  style={{
                    color: location.pathname !== "/home" ? "white" : "#1E2234",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  size={15}
                />
              ) : (
                <IoIosArrowDown
                  style={{
                    color: location.pathname !== "/home" ? "white" : "#1E2234",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  size={15}
                />
              )}
            </div>
            <PrivateLink
              to="/aboutus"
              title="About"
              textStyles={{
                fontsize: 10,
                fontWeight: 550,
                color: location.pathname !== "/home" ? "white" : "#1E2234",
                cursor: "pointer",
                letterSpacing: "0.15px",
              }}
              className={"open-sans"}
            />
            <PrivateLink
              to="/contact-us"
              title="Contact"
              textStyles={{
                fontsize: 10,
                color: location.pathname !== "/home" ? "white" : "#1E2234",
                cursor: "pointer",
                fontWeight: 550,
                letterSpacing: "0.15px",
              }}
              className={"open-sans"}
            />
            <PrivateLink
              to="/login"
              title="Login"
              textStyles={{
                fontsize: 10,
                color: location.pathname !== "/home" ? "white" : "#1E2234",
                cursor: "pointer",
                fontWeight: 550,
                letterSpacing: "0.15px",
              }}
              className={"open-sans"}
            />
          </div>
        </div>
      </div>
      {openNavbar && (
        <div
          style={{
            minWidth: 400,
            backgroundImage: "linear-gradient(to right , #4947B8, #FF9700)",
            position: "absolute",
            top: 60,
            zIndex: 1000,
          }}
          id={"web-nav"}
          className="flex py-5 px-10 w-full justify-end"
          ref={ref}
          // onMouseEnter={() => {
          //   setOpenNavbar(true);
          // }}
          // omMouseLeave={() => {
          //   setOpenNavbar(false);
          // }}
        >
          <div />
          <div
            style={{
              maxWidth: "600px",
              marginRight:width<1288?"10px":"50px"
            }}
            id="web-nav"
            className="grid grid-rows-2 grid-cols-4 gap-16 gap-y-4 "
            // ref={ref}
            // onMouseEnter={() => {
            //   setOpenNavbar(true);
            // }}
            // omMouseLeave={() => {
            //   setOpenNavbar(false);
            // }}
          >
            {NavBarOptionsWeb &&
              NavBarOptionsWeb?.map((obj, index) => (
                <div
                  style={{
                    width: "auto",
                    cursor: "pointer",
                  }}
                  className="flex items-center"
                  key={index}
                >
                  <PrivateLink
                    to={obj.to}
                    isLink={obj.isLink}
                    title={obj.name}
                    textColor={"white"}
                    width={width}
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                    textStyles={{
                      fontSize: 14,
                    }}
                    onClick={() => {
                      setOpenNavbar(false);
                    }}
                  ></PrivateLink>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default TopNavbar;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineMobile } from "react-icons/ai";
import { BsAt } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import {
  BLACK,
  BUTTON_COLOR,
  DARK_BLUE,
  LIGHT_FONT,
  LIGHT_TEXT_COLOR,
  WHITE,
} from "../../../utility/colorManager";
import { Success, Glass } from "../../../utility/assetsManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomButton from "../../components/custom_button";
import CustomLoader from "../../components/custom_loader/loader";
import CustomText from "../../components/custom_text";
import "./index.css";
import Consent from "../../../components/consent";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

export const TextInput = ({
  title,
  value,
  onChange,
  placeholder,
  style,
  width,
  height,
  type,
  name,
  required = false,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <CustomText
        style={{
          color: "#7A7979",
          fontSize: 14,
          margin: "10px 0px",
        }}
      >
        {title}
      </CustomText>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        style={{
          outline: "none",
          width: "100%",
          borderBottomWidth: 1,
          borderBottomColor: LIGHT_FONT,
          backgroundColor: DARK_BLUE,
          marginBottom: 10,
          paddingBottom: 10,
          ...style,
          color: "#CBCACA",
        }}
        required={required}
      />
    </div>
  );
};

const Container = ({ icon, name, subtitle }) => {
  let { width } = ResizeListener();
  return (
    <div
      className="flex items-start my-4   justify-center items-center"
      style={{
        width: "fit-content",
        marginTop: width > 645 ? "28px" : "0px",
      }}
    >
      <div
        className="h-full flex flex-col"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          padding: 5,
          borderRadius: "50%",
        }}
      >
        {icon}{" "}
      </div>
      <div
        style={{
          maxWidth: 300,
        }}
      >
        <CustomText
          style={{
            fontWeight: "500",
            marginLeft: 10,
            fontSize: 14,
            color: LIGHT_FONT,
          }}
        >
          {name}
        </CustomText>{" "}
        {subtitle && (
          <CustomText
            style={{
              fontWeight: "400",
              marginLeft: 10,
              fontSize: 14,
              color: LIGHT_FONT,
            }}
          >
            {subtitle}
          </CustomText>
        )}
      </div>
    </div>
  );
};

export const Checkboxes = ({ title, value, onChange, containerStyle }) => {
  return (
    <div className="flex item-center" id="container" style={containerStyle}>
      <input
        type="checkbox"
        style={{
          height: 20,
          width: 20,
        }}
        checked={value}
        className="checkbox"
        onChange={(val) => {
          onChange(val.target.name);
        }}
        name={title}
      />

      <CustomText
        style={{
          marginLeft: 10,
          fontSize: 12,
          fontWeight: "500",
          color: "#CBCACA",
          letterSpacing: 0.15,
          marginRight: "5px",
        }}
      >
        {title}
      </CustomText>
    </div>
  );
};
function ContactUs() {
  const { width, height } = ResizeListener();
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isPrepaid, setPrepaid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([]);
  const { state } = useLocation();
  const [thankyou, setThankyou] = useState("");
  const navigate = useNavigate();
  const [consentValue, setConsentValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openProfileX, setOpenProfileX] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const handleFeedback = () => {
    setFeedback(!feedback);
  };

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleOpenProfileX = () => {
    setOpenProfileX(!openProfileX);
  };

  const onChangeCheckBox = (val) => {
    if (features.includes(val)) {
      if (val === "Prepaid Card" && isPrepaid) {
      } else {
        let filterData = features.filter((obj, i) => obj !== val);
        setFeatures([...filterData]);
      }
    } else setFeatures([...features, val]);
  };

  useEffect(() => {
    if (state) {
      setPrepaid(true);
      setFeatures([...features, "Prepaid Card"]);
    }
  }, [state]);

  const inputStyle = {
    width: "100%",
  };

  useEffect(() => {
    const pathArr = window.location.href.split("?");
    if (pathArr[1]) {
      setLoading(false);
      const tokenArr = pathArr[1].slice(9);
      setThankyou(tokenArr);
    }
  }, []);

  const resetAfter5Sec = () => {
    setTimeout(() => {
      navigate("/contact-us");
      setThankyou("");
    }, 8000);
  };

  useEffect(() => {
    if (thankyou) {
      resetAfter5Sec();
    }
  }, [thankyou]);

  return (
    <div
      className="w-full flex flex-1 px-4 py-20 flex-col-reverse md:flex-row justify-center"
      style={{
        marginTop: width > 645 ? "0px" : "-205px",
        backgroundColor: DARK_BLUE,
      }}
    >
      <Helmet>
        <title>Neokred | Contact Us</title>
      </Helmet>
      <div
        style={{
          marginRight: width > 645 ? "170px" : "0px",

          width: width > 645 ? "45%" : "100%",
        }}
        className="w-full ml-2 sm:ml-0 lg:w-2/4"
      >
        <div>
          <CustomText
            style={{
              ...styles.ready,
              marginTop: width > 645 ? "1rem" : "0.5rem",
              marginLeft: width > 645 ? "0rem" : "1rem",
              fontWeight: 600,
              fontSize: width > 645 ? 32 : 28,
            }}
          >
            Contact Information
          </CustomText>
          <CustomText
            style={{
              ...styles.para,
              fontSize: 15,
              marginTop: width > 645 ? "0.5rem" : "0.7rem",
              marginLeft: width > 645 ? "0rem" : "1rem",
            }}
          >
            Fill up the form and our Team will get back to you within 24 hours.{" "}
          </CustomText>
        </div>

        <div
          style={{
            marginTop: width > 645 ? "1.5rem" : "3.5rem",
            marginLeft: width > 645 ? "0rem" : "1rem",
          }}
          className="mt-14"
        >
          <CustomText
            style={{
              ...styles.ready,
              fontWeight: 600,
              fontSize: width > 645 ? 24 : 22,
              marginBottom: width > 645 ? "0px" : "10px",
            }}
          >
            Business Queries
          </CustomText>
          <div style={{ width: "160px" }}>
            <a href="tel:+91-9538495000" className="contact">
              <Container
                name="+91-9538495000"
                icon={<AiOutlineMobile size={20} color={WHITE} />}
              />
            </a>
          </div>
          <div style={{ width: "165px" }}>
            <a href="mailto:sales@neokred.tech">
              <Container
                name="sales@neokred.tech"
                icon={<MdOutlineMail size={20} color={WHITE} />}
              />
            </a>
          </div>

          <CustomText
            style={{
              ...styles.ready,
              marginTop: 40,
              fontWeight: 600,
              fontSize: width > 645 ? 24 : 22,
              marginBottom: width > 645 ? "0px" : "10px",
            }}
          >
            Support Queries
          </CustomText>
          <div style={{ width: "138px" }}>
            <a href="tel:080-41711250">
              <Container
                name="080-41711250"
                icon={<AiOutlineMobile size={20} color={WHITE} />}
              />
            </a>
          </div>
          <div style={{ width: "270px" }}>
            <a href="mailto:support@neokred.tech">
              <Container
                name="support@neokred.tech"
                subtitle="Mon - Fri: 10:00 AM - 6:00 PM (IST)"
                icon={<MdOutlineMail size={20} color={WHITE} />}
              />
            </a>
          </div>

          <div style={{ width: "260px" }}>
            <a href="https://goo.gl/maps/Qmwu3JjgixdbaJ4B9">
              <Container
                name={`Registered Address:`}
                icon={<BsAt size={20} color={WHITE} />}
                subtitle="No 307, 9th Main Road, 5th Block, Jayanagar, Bengaluru-560041 Bengaluru Bangalore KA 560041"
              />
            </a>
          </div>
          <div style={{ width: "260px" }}>
            <a href="https://goo.gl/maps/Qmwu3JjgixdbaJ4B9">
              <Container
                name={`Corporate Office:`}
                icon={<BsAt size={20} color={WHITE} />}
                subtitle="Neokred Technologies, #307/14, Diamond Arcade, 9th Main,  39th A Cross, 5th Block, Jayanagar,  Bangalore-41"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="py-10 box-border flex flex-col items-center justify-center rounded-lg"
        style={{
          maxWidth: width > 645 ? 500 : 400,
          marginTop: width > 645 ? "-40px" : "0px",
          paddingTop: width > 645 ? "0px" : "93px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {thankyou ? (
          <div>
            <div
              style={{
                width: width > 648 ? "100%" : "50%",
                height: width > 648 ? "100%" : "50%",
                marginLeft: width > 648 ? "" : "40px",
              }}
            >
              <div
                style={{
                  width: width > 640 ? "500px" : "300px",
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "top",
                  alignItems: "center",
                  boxSizing: "border-box",
                  padding:
                    width > 640 ? "30px 10px 0px 0px" : "30px 20px 0px 15px",
                  backgroundColor: DARK_BLUE,
                  backgroundImage: `url(${Success}),url(${Glass})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "140px,280px",
                  height: width > 640 ? "600px" : "40vh",
                  position: "relative",
                  // background:"linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
                  // opacity: 0.1,
                  // transform: "matrix(-1, 0, 0, 1, 0, 0)"
                }}
              >
                <div className="flex flex-col items-center justify-center">
                  <h1
                    style={{
                      color: WHITE,
                      width: width > 640 ? "150%" : "480px",
                      fontWeight: 700,
                      fontSize: 14,
                      position: "absolute",
                      top: "250px",
                      paddingTop: width > 640 ? 100 : "",
                      paddingLeft: width > 640 ? "520px" : "265px",
                      justifyItems: "center",
                      paddingBottom: 30,
                    }}
                  >
                    Thank you for reaching out to us
                  </h1>
                  <div>
                    <h3
                      style={{
                        color: WHITE,
                        width: width > 640 ? "500px" : "300px",
                        fontWeight: 450,
                        fontSize: 11,
                        top: "268px",
                        paddingTop: width > 640 ? 102 : "",
                        paddingLeft: width > 640 ? "126px" : "8px",
                        position: "absolute",
                        justifyItems: "center",
                        paddingBottom: 30,
                      }}
                    >
                      Our representative will reach out to you in 4-7days
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <form
            action="https://formsubmit.co/sales@neokred.tech"
            method="POST"
            type="email"
            style={{
              width: width > 645 ? "100%" : "80%",
              marginLeft: width > 645 ? "0px" : "30px",
            }}
          >
            <input
              type="hidden"
              name="_next"
              value="http://neokred.tech/contact-us?thankyou=true"
            />
            <input type="hidden" name="_captcha" value="false" />
            <TextInput
              title="Full Name"
              value={fullName}
              onChange={(val) => setFullName(val.target.value)}
              height={height}
              width={width}
              style={{
                paddingTop: 10,
              }}
              type="text"
              name={"name"}
              required={true}
              placeholder="Enter your name"
            />
            <TextInput
              title="Company Name"
              value={companyName}
              onChange={(val) => setCompanyName(val.target.value)}
              height={height}
              width={width}
              style={{
                paddingTop: 10,
              }}
              type="text"
              name={"companyName"}
              required={true}
              placeholder="Enter your company name"
            />
            <TextInput
              title="Mobile Number"
              value={mobileNumber}
              onChange={(val) => setMobileNumber(val.target.value)}
              height={height}
              width={width}
              style={{
                paddingTop: 10,
              }}
              type="number"
              name={"mobile"}
              required={true}
              placeholder="Enter your mobile number"
            />
            <TextInput
              title="Company Email"
              value={companyEmail}
              onChange={(val) => setCompanyEmail(val.target.value)}
              height={height}
              width={width}
              style={{
                paddingTop: 10,
              }}
              type="email"
              name="email"
              required={true}
              placeholder="Enter your work email"
            />

            <div className="w-full p-0 m-0" style={{}}>
              <CustomText
                style={{
                  color: "#7A7979",
                  fontSize: 14,
                  paddingTop: 10,
                }}
              >
                What are you looking for
              </CustomText>

              <div
                className="flex flex-wrap gap-2"
                style={{ paddingTop: 10, color: "#fff" }}
              >
                <button style={{ display: "block" }} onClick={handleOpen}>
                  <CustomText
                    style={{
                      color: "#fff",
                      fontSize: 14,
                      paddingTop: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Collectbot{" "}
                    {open ? (
                      <FaCaretUp style={{ marginLeft: "10px" }} />
                    ) : (
                      <FaCaretDown style={{ marginLeft: "10px" }} />
                    )}
                  </CustomText>
                </button>
              </div>

              {open && (
                <>
                  <div
                    style={{
                      border: "0.5px solid rgb(203, 202, 202)",
                      margin: "10px 0 10px",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      fontSize: 14,
                      gap: 4,
                      flexWrap: "wrap",
                      margin: "2px 0",
                    }}
                  >
                    <Checkboxes
                      title="Payment Gateway"
                      onChange={(v) => {
                        onChangeCheckBox(v);
                      }}
                      containerStyle={{
                        height: "100%",
                      }}
                    />

                    <Checkboxes
                      title="Payouts"
                      onChange={(v) => {
                        onChangeCheckBox(v);
                      }}
                      containerStyle={{ height: "100%" }}
                    />
                    <Checkboxes
                      title="Virtual Account Number"
                      onChange={(v) => {
                        onChangeCheckBox(v);
                      }}
                      containerStyle={{ height: "100%" }}
                    />
                  </div>
                </>
              )}
              {/* PROFILE X */}
              <div
                className="flex flex-wrap "
                style={{ paddingTop: 10, color: "#fff" }}
              >
                <button
                  style={{ display: "block" }}
                  onClick={handleOpenProfileX}
                >
                  <CustomText
                    style={{
                      color: "#fff",
                      fontSize: 14,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ProfileX{" "}
                    {openProfileX ? (
                      <FaCaretUp style={{ marginLeft: "10px" }} />
                    ) : (
                      <FaCaretDown style={{ marginLeft: "10px" }} />
                    )}
                  </CustomText>
                </button>
              </div>
              {openProfileX && (
                <>
                  <div
                    style={{
                      border: "0.5px solid rgb(203, 202, 202)",
                      margin: "10px 0 10px",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      fontSize: 14,
                      gap: 4,
                      flexWrap: "wrap",
                      margin: "2px 0",
                    }}
                  >
                    <Checkboxes
                      title="KYC API"
                      onChange={(v) => {
                        onChangeCheckBox(v);
                      }}
                      containerStyle={{ height: "100%" }}
                    />

                    <Checkboxes
                      title="KYB API"
                      onChange={(v) => {
                        onChangeCheckBox(v);
                      }}
                      containerStyle={{ height: "100%" }}
                    />

                    <Checkboxes
                      title="Customer Mobile Number Based Intelligence"
                      onChange={(v) => {
                        onChangeCheckBox(v);
                      }}
                      containerStyle={{ height: "100%" }}
                    />
                  </div>
                </>
              )}

              {/* Others */}
              <div
                className="flex flex-wrap"
                style={{ paddingTop: 10, color: "#fff" }}
              >
                <button style={{ display: "block" }} onClick={handleFeedback}>
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      fontSize: 14,
                      margin: "2px 0",
                    }}
                  >
                    <CustomText
                      style={{
                        color: "#fff",
                        fontSize: 14,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Others{" "}
                      {feedback ? (
                        <FaCaretUp style={{ marginLeft: "10px" }} />
                      ) : (
                        <FaCaretDown style={{ marginLeft: "10px" }} />
                      )}
                    </CustomText>
                  </div>
                </button>
                {feedback && (
                  <div
                    style={{
                      width: "100%",
                      height: "120px",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column", // Added to arrange children in a column
                    }}
                  >
                    <textarea
                      style={{
                        marginTop: 10,
                        width: "100%",
                        height: "80px",
                        background: "transparent",
                        color: "#fff",
                        borderBottom: "1px solid rgb(203, 202, 202)",
                        borderRadius: "1px",
                        fontSize: 14,
                      }}
                      defaultValue="What's on your mind?"
                    />
                  </div>
                )}
              </div>
            </div>

            {features && features.includes("Others") && (
              <TextInput
                title="What's in your mind? (Others)"
                value={message}
                onChange={(val) => setMessage(val.target.value)}
                height={height}
                width={width}
                style={{
                  height: 80,
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  display: "flex",
                  ...inputStyle,
                  color: "#CBCACA",
                  width: "100%",
                }}
                placeholder="Type here!"
                name="message"
              />
            )}
            {/* <CustomButton
            styles={{
              width: "100%",
              margin: "10px 0px",
              padding: "25px",
            }}
            backgroundColor={PRIMARY_COLOR}
            title="Send Message"
            textStyles={{
              fontSize: 14,
            }}
            onClick={() => {}}
          /> */}

            <Consent
              onClick={(val) => {
                setConsentValue(val);
              }}
            />

            <CustomButton
              title={loading ? <CustomLoader /> : "Send Message"}
              style={{
                width: "100%",
                margin: "10px 0px",
                padding: "18px",
                color: "white",
                borderRadius: "8px",
                marginTop: 30,
              }}
              onClick={() => {
                if (
                  fullName !== "" &&
                  mobileNumber !== "" &&
                  companyEmail !== "" &&
                  companyName !== ""
                ) {
                  setLoading(true);
                }
              }}
              disabled={consentValue !== "consent"}
            ></CustomButton>
          </form>
        )}
      </div>
      <div className="content md:hidden mb-6">
        <CustomText
          style={{
            color: LIGHT_TEXT_COLOR,
            fontSize: 18,
          }}
        >
          Contact us!
        </CustomText>
        <CustomText
          style={{
            color: BUTTON_COLOR,
            fontSize: 22,
            fontWeight: "bold",
          }}
        >
          How can we help you?
        </CustomText>
      </div>
    </div>
  );
}

const styles = {
  ready: {
    fontSize: 35,
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 100.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "900",
    lineHeight: 1.2,
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    color: LIGHT_FONT,
  },

  container: {
    backgroundColor: DARK_BLUE,
    width: "100%",
    height: "100%",
  },
  liStyles: {
    listStyleType: "disc",
    fontSize: 19,
    color: BLACK,
    fontWeight: "400",
    marginTop: 20,
    minWidth: 300,
    maxWidth: 450,
  },
  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
};

export default ContactUs;

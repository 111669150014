import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Product1,
  Product2,
  Product3,
  Product4,
 // Product5,
  Product6,
  Product7,
  Product8,
} from "../../../utility/assetsManager";
import ResizeListener from "../../../utility/ResizeListener";
import "./productscroll.css";

const images = [0, 1, 2, 3, 4, 5, 6, 7];
// const images = [0, 1, 2, 3, 4, 5, 6, 7, 8];

const Data = [
  {
    img: Product1,
    title1: "Super card for your daily needs.",
    para: "A prepaid card for anything. Get your unique card for insurances, students, payrolls, incentives, expenses, or gifts. You name it, we make it.",
  },
  {
    img: Product1,
    title1: "Super card for your daily needs.",
    para: "A prepaid card for anything. Get your unique card for insurances, students, payrolls, incentives, expenses, or gifts. You name it, we make it.",
  },
  {
    img: Product2,
    title1: "Get control of your financial health",
    title2: "financial health",
    para: "Manage and make the best financial decisions with our Datai to reduce overspending and increase productivity.",
  },
  {
    img: Product3,
    title1: "Trendy, cool, smart payment accessories",
    title2: "payment accessories",
    para: "Make safe contactless transactions, without pulling your card out of your wallet with our trendy payment accessories.",
  },
  {
    img: Product4,
    title1: "Collectbot, frictionless flow of funds",
    title2: "flow of funds",
    para: "Organizations and treasury management are evolving, and so must the way we manage our funds. Introducing Collectbot to help you keep your funds flowing smoothly.",
  },
  // {
  //   img: Product5,
  //   title1: "Enhance your lending process",
  //   title2: "process",
  //   para: "Comprehensive end-to-end loan management system for your company. LendX can help you streamline your loan management process.",
  // },
  {
    img: Product6,
    title1: "Not just another Gift Card",
    title2: "Card",
    para: "Give your friends and employees the freedom to select their own gifts. You can use our gifting solution to reward your partners, recognise your employees, and retain your loyal customers.",
  },
  {
    img: Product7,
    title1: "Progressive KYC solution",
    title2: "solution",
    para: "Unified platform for end-to-end individual and business onboarding and monitoring, including smart and integrated risk mitigation tools.",
  },
  {
    img: Product8,
    title1: "Superfast onboarding process",
    title2: "process",
    para: "ProfileX can retrieve necessary data for smooth user onboarding process with just a mobile number.",
  },
];

const ProductScroll = () => {
  const { width } = ResizeListener();
  const [visibleImagesMap, setVisibleImagesMap] = useState(
    images.reduce((map, image) => {
      map[image] = false;
      return map;
    }, {})
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop * 1;

      const viewportHeight = window.innerHeight * 1.2;

      const newVisibleImagesMap = images.reduce((map, image) => {
        map[image] = scrollTop >= image * viewportHeight;
        return map;
      }, {});

      setVisibleImagesMap(newVisibleImagesMap);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    for (const property in visibleImagesMap) {
      if (visibleImagesMap[property]) {
        setCurrentIndex(parseInt(property));
      }
    }
  }, [visibleImagesMap]);

  return (
    <div className="app">
      <div className="sticky" style={{ top: width > 645 ? "0px" : "44px" }}>
        <div className="frame">
          <div
            className="prod-slider"
            style={{
              minWidth: 280,
              maxWidth: width > 645 ? 500 : 355,
              backgroundColor: "#1E2234",
              maxHeight: width > 1232 ? 500 : 200,
              minHeight: 300,
              borderRadius: 20,
              width: "100%",
              height: "100%",
              position: "absolute",
              marginTop: width > 640 ? 10 : 10,
              marginLeft: width > 649 ? 70 : 0,
            }}
          >
            {images.map((image) => {
              return (
                <div
                  className={currentIndex === image ? "slide current" : "slide"}
                  key={image}
                >
                  {image === currentIndex && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        padding:
                          width > 645
                            ? "20px 20px 0px 20px"
                            : "10px 10px 0px 10px",
                      }}
                    >
                      <img
                        src={Data[currentIndex].img}
                        className={"slide_img"}
                        alt="product-img"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          

          <div className="scroll-text-container">
            <div
              className="scroll-text-subcontainer"
              style={{
                
                marginBottom: width > 645 ? "0px" : "100px",
                
              }}
            >
              {Data.map((data, index) => {
                return (
                  <div
                    className={
                      currentIndex === index ? "hid-box show-box" : "hid-box"
                    }
                    key={index}
                  >
                    {index === currentIndex && (
                      <div style={{ height: width > 645 ? "310px" : "253px" }}>
                        <p
                          className="scroll-text-title"
                          style={{ margin: "0px" }}
                        >
                          {data.title1}
                        </p>
                        <p
                          className="scroll-text-subtitle"
                          style={{
                            fontSize: width > 645 ? "18px" : "16px",
                            margin: "0px",
                            marginTop: "10px",
                          }}
                        >
                          {data.para}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductScroll;

import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Layout from "./layouts";
import RouteIndex from "./routes";
import Cookies from "./components/cookies";

function App() {
  const [open, setOpen] = useState(true);
  const isCookiesAction = localStorage.getItem("COOKIES");

  return (
    <BrowserRouter>
      <Layout>
        <RouteIndex />
      </Layout>

      {isCookiesAction !== "SET" && (
        <div
          className="modal-overlay"
          onClick={() => {
            // localStorage.setItem("COOKIES", "SET");
            // setOpen(false);
          }}
        >
          <div className="modal">
            <div className="modal-header"></div>
            <div className="modal-content">
              <Cookies
                onClick={() => {
                  localStorage.setItem("COOKIES", "SET");
                  setOpen(false);
                }}
                onClickReject={() => {
                  localStorage.setItem("COOKIES", "SET");
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </BrowserRouter>
  );
}

export default App;

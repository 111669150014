import React from "react";
import {
  EKYC1SVG,
  EKYC2SVG,
  EkycSvg,
  EllipseSvg,
  FacialSvg,
  IdSvg,
  OcrSvg,
  ScanSvg,
  VideoSvg,
} from "../../../utility/assetsManager";
import { BLACK, DARK_BLUE, WHITE } from "../../../utility/colorManager";
import ResizeListener from "../../../utility/ResizeListener";
import CustomBrilliance from "../../components/custom_brilliance";
import CustomText from "../../components/custom_text";
import MainHomeComponent from "../../components/Main_component";
import "./index.css";

export const Container = ({ icon, title, style }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
        maxWidth: 150,
      }}
    >
      <img
        src={icon}
        alt={title}
        height={50}
        width={50}
        style={{
          ...style,
        }}
      />
      <CustomText
        style={{
          fontSize: "10px",
          color: "#1E2234",
          textAlign: "center",
          lineHeight: 1.1,
          marginTop: 20,
          fontWeight: "500",
        }}
      >
        {title}
      </CustomText>
    </div>
  );
};

const LeftComponent = ({ width }) => {
  return (
    <div
      style={{
        margin: "auto",
        textAlign: "center",
        minHeight: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="flex flex-col items-center justify-center"
    >
      <p
        style={{
          ...styles.ready,
          maxWidth: width > 640 ? "" : "250px",
          marginBottom: width > 640 ? "" : "",
          textAlign: "center",
        }}
        className="text-3xl md:text-2xl lg:text-5xl"
      >
        Click. Upload. Verified!
      </p>

      <p
        style={{
          ...styles.para,
          margin: width < 640 ? "10px 0px " : "10px 0px",
          maxWidth: width > 640 ? "" : "250px",
          paddingTop: width > 640 ? "10px" : "10px",
        }}
        className="w-full text-center text-base	md:w-7/12	lg:text-2xl"
      >
        We provide a complete security check and ID verification with a
        progressive KYC solution for your users.
      </p>
    </div>
  );
};

const LeftComponent2 = () => {
  return (
    <div className="md:pl-10 lg:pl-0">
      <p style={{ ...styles.ready, marginTop: 30 }} className="text-3xl lg:text-3xl">
        Get your customers onboard remotely and rapidly.
      </p>

      <p
        style={{ ...styles.para, margin: "15px 0px", color: "#CBCACA" }}
        className="text-xl lg:text-xl"
      >
        Onboard your customers with better eKYC solutions mandated by
        regulations.
      </p>

      <div
        style={{
          marginTop: 26,
          marginLeft: 10
        }}
      >
        <ul style={styles.liStyles}>
          <li className="text-1xl lg:text-xl ">Easy to navigate information</li>
          <li className="text-1xl lg:text-xl ">
            A smoother onboarding experience
          </li>

          <li className="text-1xl lg:text-xl ">
            Enhance the security of data in KYC
          </li>
          <li className="text-1xl lg:text-xl ">
            Fully automated audit trail for your clients
          </li>
          <li className="text-1xl lg:text-xl ">
            Enhance your value to clients with USP offer
          </li>
          <li className="text-1xl lg:text-xl ">
            Ensures false negatives become true positives
          </li>
          <li className="text-1xl lg:text-xl ">
            A digital record for API integration in multiple formats (including
            PDF, .xls, etc.)
          </li>
        </ul>
      </div>
    </div>
  );
};
const RightComponent2 = ({ width }) => {
  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <EKYC1SVG
        style={{
          minWidth: 320,
          objectFit: "contain",
          width: width * 0.3,
          maxWidth: 500,
        }}
      />
    </div>
  );
};

const LeftComponent3 = () => {
  return (
    <div className="md:pl-0 lg:pl-0">
      <p style={{ ...styles.ready, }} className="text-3xl lg:text-3xl">
        Before you serve your hot instant noodles, we will complete your ID
        Verification.
      </p>

      <p
        style={{ ...styles.para, margin: "15px 0px 0px 7px", color: "#6C6B6B" }}
        className="text-md lg:text-xl"
      >
        Instantly verify ID documents online with AI intelligence and
        biometrics.
      </p>

      <div
        style={{
          marginTop: 40
        }}
      >
        <ul style={{ ...styles.liStyles, color: BLACK, marginLeft: 30 }}>
          <li className="text-sm lg:text-xl ">Robust customer onboarding</li>
          <li className="text-sm lg:text-xl ">Real-time database</li>
          <li className="text-sm lg:text-xl ">Reduce manual labour</li>
          <li className="text-sm lg:text-xl ">Time and cost effective</li>

          <li className="text-sm lg:text-xl ">
            Reduce the risk of money laundering
          </li>
          <li className="text-sm lg:text-xl ">
            Collects, checks and verifies the customers’ identification
          </li>
          <li className="text-sm lg:text-xl ">
            Prevent the risk of financial crime and secure your business
          </li>
        </ul>
      </div>
    </div>
  );
};
const RightComponent3 = ({ width }) => {
  return (
    <div
      style={{
        height: "fir-content",
        display: "flex",
        alignSelf: "center",
      }}
    >
      <EKYC2SVG
        style={{
          objectFit: "contain",
          // width: width * 0.3,
          height: width > 600 ? "500px" : "320px",
          width: width > 600 ? "650px" : "410px",
        }}
      />
    </div>
  );
};

function KycIndex() {
  const { width } = ResizeListener();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          padding: width > 640 ? "30px 30px" : "20px 10px",
          display: "flex",
          backgroundColor: DARK_BLUE,
          backgroundImage: `url(${EllipseSvg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "290px",
        }}
      >
        <LeftComponent width={width} />
      </div>

      <div>
        <iframe
          src="https://drive.google.com/file/d/14_BEHRP-4EO-_4mw1XGyV88LAbagrSox/preview"
          width="100%"
          height="400"
          allow="autoplay"
          title="kyc"
        ></iframe>
      </div>

      <div
        style={{
          padding: "30px 40px",
          height: width < 648 ? "80vh" : "40vh"
        }}
      >
        <div>
          <p
            style={{
              ...styles.ready,
              fontSize: 29,
              lineHeight: 1,
              marginBottom: 10,
            }}
          >
            Our KYC Solutions
          </p>
          <p
            style={{
              ...styles.para,
              margin: "5px 0px",
              color: "#6C6B6B",
              fontSize: 16,
              lineHeight: 1.2,
            }}
          >
            Get your customers onboarded remotely and rapidly.
          </p>
        </div>

        <div
          style={{
            maxWidth: width > 600 ? "90%" : "100%",
            marginLeft: width > 600 ? "20px" : "0px",
            background: "#FFFFFF",
          }}
          className="grid justify-items-center items-center grid-cols-2 grid-rows-2 my-10 md:grid-cols-6 grid-rows-1 gap-20"
        >
          <Container title="ID Verification" icon={IdSvg} />
          <Container title="Video KYC" icon={VideoSvg} />
          <Container
            title="e-KYC"
            icon={EkycSvg}
            style={{
              marginBottom: 5,
            }}
          />
          <Container title="Liveness Detection" icon={ScanSvg} />
          <Container
            title="OCR"
            icon={OcrSvg}
            style={{
              marginBottom: 10,
            }}
          />
          <Container
            title="Facial comparison 
            verification"
            icon={FacialSvg}
          />
        </div>
      </div>

      <MainHomeComponent
        leftcomponent={<LeftComponent2 />}
        rightcomponent={<RightComponent2 width={width} />}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          padding: "30px 0px",
          backgroundColor: DARK_BLUE,
        }}
      />

      <MainHomeComponent
        leftcomponent={<LeftComponent3 />}
        rightcomponent={<RightComponent3 width={width} />}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          padding: "30px 0px",
          backgroundColor: "#FFF6EF",
        }}
        rightStyle={{
          padding: 30,
          margin: 0,
          height: "fit-content",
        }}
        leftStyle={{
          height: "fit-content",
        }}
      />

      <CustomBrilliance />
    </div>
  );
}

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "700",
    lineHeight: 1.2,
    textAlign: "start",
    padding: "0 0 10px 0",
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#CBCACA",
  },

  liStyles: {
    listStyleType: "disc",
    fontSize: 15,
    color: WHITE,
    fontWeight: "400",
    minWidth: 300,
    maxWidth: 450,
  },

  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

export default KycIndex;

import "./loader.css";

const CustomLoader = ({ size = 20 }) => {
  return (
    <>
      <div
        className="spinner"
        style={{
          borderTopColor: "white",
          height: size,
          width: size
        }}
      />
    </>
  );
};

export default CustomLoader
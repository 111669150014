import React from "react";
import {
  BekindSvg,
  EllipseSvg,
  FINANCEICON,
  LAYOFFICON,
  MONEY,
  NETWORKICON,
  PAYBILLICON,
  POLICIICON,
  POLICILOGO,
  ThinkteamSvg,
  WorksmartSvg,
} from "../../../utility/assetsManager";
import ResizeListener from "../../../utility/ResizeListener";
import { DARK_BLUE, WHITE_BG } from "../../../utility/colorManager";
import CustomText from "../../components/custom_text";
import CustomBrilliance from "../../components/custom_brilliance";
// import { Container4 } from "../about";
const Container4 = ({ title, src }) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        textAlign: "start",
        borderRadius: 7,
        minWidth: 200,
        maxHeight: 200,
        // Add a white border with 2px width
        border: "2px solid white",
      }}
      className="flex flex-col my-4 lg:my-0 justify-center text-center mx-10 px-4 py-10"
    >
      <img src={src} style={{ width: 100 }} alt="about-container-3" />

      <p
        style={{
          fontWeight: "400",
          fontSize: 15,
          padding: 0,
          margin: 0,
          width: 150,
          color: "#fffff",
          marginBottom: 30,
        }}
      >
        {title}
      </p>
    </div>
  );
};

const IconTextContainer = ({ title, Icon }) => {
  let { width } = ResizeListener();
  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(33,37,54)",
          textAlign: "start",
          borderRadius: 8,
          minWidth: 220,
          maxHeight: 200,
          // Add a white border with 2px width
          border: "1px solid white",
        }}
        className="mx-2 mb-10 flex flex-col justify-evenly items-center rounded-lg"
      >
        <img src={Icon} height={60} width={50} alt={title} className=" my-4" />
        <CustomText
          style={{
            textAlign: "center",
            fontWeight: "500",
            color: "#fff",
            padding: "10px 10px",
            transition: "all 0.5s",
            width: width > 645 ? "178px" : "170px",
            minHeight: 50,
          }}
          className="text-base"
        >
          {title}
        </CustomText>
      </div>
    </>
  );
};
const IconTextContainer2 = ({ title, Icon, description }) => {
  let { width } = ResizeListener();
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          textAlign: "start",
          borderRadius: 8,
          minWidth: 180,
          maxHeight: width < 640 ? 300 : 240,
          // Add a white border with 2px width
        }}
        className="m-4 flex flex-col justify-evenly items-center rounded-lg"
      >
        <div
          style={{
            borderRadius: "40px",
            border: "3px solid  #A696BD",
            background: "#F9FAFD",
            mixBlendMode: "multiply",
            padding: "1.5em",
          }}
        >
          <img src={Icon} height={60} width={60} alt={title} className="m-4" />
        </div>

        <CustomText
          style={{
            textAlign: "center",
            fontWeight: "500",
            color: "##31384C",
            // padding: "15px 10px",
            transition: "all 0.5s",
            width: width > 645 ? "200px" : "160px",
            // minHeight: 30,
            marginTop: "10px",
          }}
          className="text-base"
        >
          {title}
        </CustomText>
        <CustomText
          style={{
            textAlign: "center",
            fontWeight: "400",
            color: "#fff",
            // padding: "5px 5px",
            transition: "all 0.5s",
            width: width > 645 ? "240px" : "160px",
            // minHeight: 50,
            color: "#545A6A",
            marginTop: "12px",
          }}
          className="text-sm leading-normal mt-4"
        >
          {description}
        </CustomText>
      </div>
    </>
  );
};

const LeftComponent = ({ width }) => {
  return (
    <>
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          minHeight: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="flex flex-col items-center justify-center"
      >
        <div
          style={{
            padding: width > 320 ? "30px 30px" : "20px 10px",
            display: "flex",
            // backgroundColor: WHITE_BG,
            backgroundImage: `url(${EllipseSvg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "120px",
            backgroundPosition: "center",
          }}
        >
          <POLICILOGO
            style={{
              minWidth: 200,
              objectFit: "contain",
              width: width * 0.1,
              maxWidth: 500,
              marginBottom: 10,
            }}
          />
        </div>
        <p
          style={{
            ...styles.para,
            maxWidth: width > 640 ? "" : "240px",
            paddingTop: width > 640 ? "10px" : "10px",
          }}
          className="flex items-center justify-center w-full text-center text-base	md:w-7/12	lg:text-2xl pb-4 -mt-8"
        >
          Your Financial future is too important to leave a chance
        </p>
        <button className="text-[#ffff] bg-[#27005D] rounded-xl px-8 py-2 mt-2">
          Coming soon
        </button>
      </div>
    </>
  );
};

const Polici = () => {
  const { width } = ResizeListener();

  return (
    <>
      <div>
        <div
          style={{
            padding: width > 640 ? "30px 30px" : "20px 10px",
            display: "flex",
            // backgroundColor: WHITE_BG,
            backgroundImage: `url(${EllipseSvg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "300px",
          }}
        >
          <LeftComponent width={width} />
        </div>
        <div
          style={{
            background: DARK_BLUE,
          }}
          className="py-10"
        >
          <div
            style={{
              textAlign: "center",
            }}
            className="flex flex-col justify-center items-center relative"
          >
            <p
              style={{
                ...styles.ready,
                fontSize: 28,
              }}
            >
              What we got for you
            </p>
            {/* RELATIVE */}
            <div className="flex flex-wrap sm:flex-1  justify-center items-center mt-10 mb-15 whitespace-pre-wrap">
              <div>
                <IconTextContainer
                  Icon={MONEY}
                  title={"3 months of \nSalary"}
                />
              </div>
              <div>
                <IconTextContainer
                  Icon={POLICIICON}
                  title={"Placement \nAssistant"}
                />
              </div>
              <div>
                <IconTextContainer
                  Icon={NETWORKICON}
                  title={"Access to network of hiring professional"}
                />
              </div>
            </div>
            {/* ABSOLUTE CHILD */}
            {/* <div class="absolute flex flex-col bg-[#fff] -bottom-80 items-around justify-around  w-[80%] rounded-xl shadow-md   mt-5">
              <div>
                <p
                  style={{
                    ...styles.ready2,
                    fontSize: 24,
                  }}
                  className="mt-5"
                >
                  Why do you need Salary Protect Plan?
                </p>
                <div className="flex flex-wrap sm:flex-1 justify-around mt-10 whitespace-pre-wrap mb-4">
                  <div>
                    <IconTextContainer2
                      Icon={LAYOFFICON}
                      title={"Rise in Layoff"}
                      description={
                        "Layoffs are on the rise, leaving many employees worried about their jobs."
                      }
                    />
                  </div>
                  <div>
                    <IconTextContainer2
                      Icon={FINANCEICON}
                      title={"Financial Support"}
                      description={
                        "A secure financial and take care of your essential needs."
                      }
                    />
                  </div>
                  <div>
                    <IconTextContainer2
                      Icon={PAYBILLICON}
                      title={"Pay Bills on Time"}
                      description={
                        "Pay your bills on time, for less stress and more peace of mind."
                      }
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div
          style={{
            background: `linear-gradient(to bottom, ${DARK_BLUE} 0%, ${DARK_BLUE} 45%, ${WHITE_BG} 45%, ${WHITE_BG} 100%)`,
          }}
          className="py-10"
        >
          <div
            style={{
              textAlign: "center",
            }}
            className="flex flex-col justify-center items-center"
          >
            <div class=" flex flex-col bg-[#fff]  items-around justify-around  w-[80%] rounded-2xl shadow-md mt-5">
              <div>
                <p
                  style={{
                    ...styles.ready2,
                    fontSize: 24,
                  }}
                  className="mt-5"
                >
                  Why do you need Salary Protect Plan?
                </p>
                <div className="flex flex-wrap sm:flex-1 justify-around mt-10 whitespace-pre-wrap mb-4">
                  <div>
                    <IconTextContainer2
                      Icon={LAYOFFICON}
                      title={"Rise in Layoff"}
                      description={
                        "Layoffs are on the rise, leaving many employees worried about their jobs."
                      }
                    />
                  </div>
                  <div>
                    <IconTextContainer2
                      Icon={FINANCEICON}
                      title={"Financial Support"}
                      description={
                        "A secure financial and take care of your essential needs."
                      }
                    />
                  </div>
                  <div>
                    <IconTextContainer2
                      Icon={PAYBILLICON}
                      title={"Pay Bills on Time"}
                      description={
                        "Pay your bills on time, for less stress and more peace of mind."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <CustomBrilliance />
      </div>
    </>
  );
};

const styles = {
  ready: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "700",
    lineHeight: 1.2,
    textAlign: "start",
    padding: "0 0 20px 0",
  },
  ready2: {
    background: "linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Satoshi",
    fontWeight: "700",
    lineHeight: 1.2,
    textAlign: "center",
    // padding: "0 0 10px 0",
  },

  para: {
    fontFamily: "Satoshi",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#7181A1",
  },

  liStyles: {
    listStyleType: "disc",
    fontSize: 15,
    // color: WHITE,
    fontWeight: "400",
    minWidth: 300,
    maxWidth: 450,
  },

  bottomContainer: {
    background: " linear-gradient(90deg, #4947B8 0%, #FF9700 166.16%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "40vh",
    flexDirection: "column",
    padding: "100px 0px",
  },
  text1: {
    color: "white",
    fontSize: 30,
    fontWeight: 700,
  },
  text2: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    // transform: "scaleX(1.1)",
  },
  image1: {
    width: "8px",
    height: "8px",
    marginBottom: "3px",
  },
};

export default Polici;
